import React, { Component } from 'react'
import { connect } from 'react-redux'
import { changeMeetingType, MEETINGTYPE_MEETING, MEETINGTYPE_SAUNA, MEETINGTYPE_PARTY, FETCHING_PRODUCTS_STATUS_SUCCESS } from '../../../../../../state/reduxApp'
import { localizeObject } from '../../../../../../services/localizationService'
import PreReservationbutton from '../../../../buttons/preReservationbutton'
import CompanyIdSelector from '../../../../../search/searchInputs/companyIdSelector/companyIdSelector'
import RadioButton from '../../../../../common/radioButton/radioButton'
import VenuePackageButton from './venuePackageButton/venuePackageButton'
import venueListItemStyles from '../venueListItemStyles.module.css'
import meetingTypeStyles from '../../../../../search/meetingTypes/meetingType.module.css'
import fonts from '../../../../../../styles/fonts.module.css'
import Notification from '../../../../../common/notification/notification'
import { injectIntl, FormattedMessage } from '../../../../../../../plugins/gatsby-plugin-intl-custom'

/*
  edge
  showNextSection
  changeUserMessage
  packageID
  packageName
  changePackageInformation
*/
class VenuePackageSelection extends Component {
  constructor() {
    super()

    this.state = {
      checked: '',
    }

    this.handleVenuePackageSelectionChange = this.handleVenuePackageSelectionChange.bind(this)
  }

  fetchMeetingrooms() {
    this.props.changePackageInformation('', '')
    this.props.dispatchMeetingType(MEETINGTYPE_MEETING)
    this.setState({ checked: '' })
  }
  fetchPartyrooms() {
    this.props.changePackageInformation('', '')
    this.props.dispatchMeetingType(MEETINGTYPE_PARTY)
    this.setState({ checked: '' })
  }
  fetchSaunarooms() {
    this.props.changePackageInformation('', '')
    this.props.dispatchMeetingType(MEETINGTYPE_SAUNA)
    this.setState({ checked: '' })
  }

  handleVenuePackageSelectionChange(id) {
    this.setState({
      checked: id,
    })
  }

  handleVenuePackageSelectionChangeOnKeypress(e, id) {
    e.preventDefault()
    if (e.charCode === 13) {
      this.handleVenuePackageSelectionChange(id)
    }
  }

  areSelectedTypePackagesFound() {
    if (!this.props.reduxVenueProducts) return null
    if (!this.props.reduxVenueProducts[0]) return null
    if (!this.props.reduxVenueProducts[0].products) return null
    if (!this.props.reduxVenueProducts[0].products.package) return null

    let selectedType

    switch (this.props.meetingType) {
      case MEETINGTYPE_MEETING:
        selectedType = 'Meeting'
        break
      case MEETINGTYPE_SAUNA:
        selectedType = 'Sauna'
        break
      case MEETINGTYPE_PARTY:
        selectedType = 'Celebration'
        break
      default:
        break
    }

    let packagesFound = false

    this.props.reduxVenueProducts[0].products.package.forEach(p => {
      if (p.types.includes(selectedType)) packagesFound = true
      return
    })

    return packagesFound
  }

  componentDidMount = () => {
    const { edge, meetingType } = this.props

    let reduxMeetingType

    switch (meetingType) {
      case MEETINGTYPE_MEETING:
        reduxMeetingType = 'Meeting'
        break
      case MEETINGTYPE_SAUNA:
        reduxMeetingType = 'Sauna'
        break
      case MEETINGTYPE_PARTY:
        reduxMeetingType = 'Celebration'
        break
      default:
        break
    }

    if (edge.venueType.find(t => t === reduxMeetingType)) return

    // if meeting type in Redux is not available for the venue, dispatch an available type

    if (edge.venueType.find(t => t === 'Meeting')) {
      this.props.dispatchMeetingType(MEETINGTYPE_MEETING)
      return
    }
    if (edge.venueType.find(t => t === 'Sauna')) {
      this.props.dispatchMeetingType(MEETINGTYPE_SAUNA)
      return
    }

    this.props.dispatchMeetingType(MEETINGTYPE_PARTY)
  }

  renderVenuePackages() {
    if (!this.props.reduxVenueProducts) return null
    if (!this.props.reduxVenueProducts[0]) return null
    if (!this.props.reduxVenueProducts[0].products) return null
    if (!this.props.reduxVenueProducts[0].products.package) return null
    if (this.props.reduxVenueProducts[0].products.package.length < 1) return null

    let shortVenuePackageMeetingTypeBy = ''
    switch (this.props.meetingType) {
      case MEETINGTYPE_MEETING:
        shortVenuePackageMeetingTypeBy = 'Meeting'
        break
      case MEETINGTYPE_SAUNA:
        shortVenuePackageMeetingTypeBy = 'Sauna'
        break
      case MEETINGTYPE_PARTY:
        shortVenuePackageMeetingTypeBy = 'Celebration'
        break
      default:
        break
    }
    return this.props.reduxVenueProducts[0].products.package.map((venuePackage, index) => {
      return (
        /*eslint-disable */
        <li className={meetingTypeStyles.venuePackageItem} key={index} tabIndex="0" onKeyPress={e => this.handleVenuePackageSelectionChangeOnKeypress(e, venuePackage.id)}>
          {/* /*eslint-enable */}
          <VenuePackageButton
            key={index}
            value={localizeObject(venuePackage.name, this.props.intl.locale)}
            description={localizeObject(venuePackage.description, this.props.intl.locale)}
            price={venuePackage.price}
            coopMemberPrice={venuePackage.coopMemberPrice}
            showPrices={this.props.reduxFetchingVenueProductsStatus === FETCHING_PRODUCTS_STATUS_SUCCESS}
            isBusinessCustomer={this.props.priceLevelCode ? 1 : 0}
            onChange={() => this.handleVenuePackageSelectionChange(venuePackage.id)}
            checked={this.state.checked === venuePackage.id}
            venuePackage={venuePackage}
            changePackageInformation={this.props.changePackageInformation}
            shortVenuePackageMeetingTypeBy={shortVenuePackageMeetingTypeBy}
          />
        </li>
      )
    })
  }

  render() {
    const { edge, localizations, meetingType } = this.props

    if (this.props.venueProducts && this.props.venueProducts[0] && this.props.venueProducts[0].products && this.props.venueProducts[0].products.package)
      venuePackages = venueProducts[0].products.package

    let buttonDisabled = false

    if (this.state.checked === '') buttonDisabled = true
    if (this.props.reduxFetchingVenueProductsStatus !== FETCHING_PRODUCTS_STATUS_SUCCESS) buttonDisabled = true

    return (
      <div>
        <div className={venueListItemStyles.contentContainer}>
          <CompanyIdSelector />
          <>
            <p className={fonts.legend}>
              <FormattedMessage id="venuePage.package.venueEventType" />:
            </p>
            <div className={meetingTypeStyles.meetingTypeButtons}>
              {edge.venueType.find(t => t === 'Meeting') && (
                <RadioButton
                  checked={this.props.meetingType === MEETINGTYPE_MEETING}
                  value="meeting"
                  label={this.props.intl.formatMessage({ id: 'search.meeting' })}
                  onChange={() => this.fetchMeetingrooms()}
                  name="meetingType"
                />
              )}
              {edge.venueType.find(t => t === 'Sauna') && (
                <RadioButton
                  checked={this.props.meetingType === MEETINGTYPE_SAUNA}
                  value="meeting"
                  label={this.props.intl.formatMessage({ id: 'search.sauna' })}
                  onChange={() => this.fetchSaunarooms()}
                  name="meetingType"
                />
              )}
              {edge.venueType.find(t => t === 'Celebration') && (
                <RadioButton
                  checked={this.props.meetingType === MEETINGTYPE_PARTY}
                  value="party"
                  label={this.props.intl.formatMessage({ id: 'search.party' })}
                  onChange={() => this.fetchPartyrooms()}
                  name="meetingType"
                />
              )}
            </div>
          </>

          <p className={fonts.smallParagraph}>
            <span className={fonts.bold}>
              <FormattedMessage id="venuePage.package.packageIsMandatory" />
            </span>

            {meetingType === MEETINGTYPE_MEETING && (
              <>
                {' '}
                <FormattedMessage id="venuePage.package.packagesInclude" />
              </>
            )}
          </p>
          <ul className={meetingTypeStyles.venuePackageList}>{this.renderVenuePackages()}</ul>
          {!this.areSelectedTypePackagesFound() && (
            <div className={meetingTypeStyles.noMeetingTypePackages}>
              <Notification type="info" text={this.props.intl.formatMessage({ id: 'venuePage.venuePackages.noPackages' }) + this.props.edge.phoneNumber} visible />
            </div>
          )}
          <PreReservationbutton
            edge={this.props.edge}
            afterPreReservationClick={index => this.props.showNextSection(index)}
            changeUserMessage={message => this.props.changeUserMessage(message)}
            buttonText={this.props.intl.formatMessage({ id: 'venuePage.nextPhase' })}
            disabled={buttonDisabled}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  priceLevelCode: state.priceLevelCode,
  meetingType: state.search.meetingType,
  reduxVenueProducts: state.data.venueProducts,
  reduxFetchingVenueProductsStatus: state.queryStatuses.fetchingVenueProductsStatus,
})
const mapDispatchToProps = dispatch => ({
  dispatchMeetingType: callbackLink => dispatch(changeMeetingType(callbackLink)),
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(VenuePackageSelection))
