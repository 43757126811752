import React from 'react'
import MakeReservation from '../components/reservation/makeReservation/makeReservation'
import { trackEvent } from '../services/analyticsService'
import { localizeObject } from '../services/localizationService'
import SEO from '../components/seo/seo'
import { useIntl } from '../../plugins/gatsby-plugin-intl-custom'

const Room = ({ pageContext: { edge } }) => {
  const intl = useIntl()

  trackEvent({
    event: 'productDetail',
    ecommerce: {
      detail: {
        products: [
          {
            name: edge.establishment.name.fi_FI,
            id: edge.id,
            category: edge.name.fi_FI,
          },
        ],
      },
    },
  })

  return (
    <>
      <SEO title={localizeObject(edge.name, intl.locale)} description={localizeObject(edge.description, intl.locale)} imageURL={edge.images.length && edge.images[0].url} />
      <MakeReservation edge={edge} />
    </>
  )
}

export default Room
