import React from 'react'
import { localizeObject } from '../../../../../../services/localizationService.js'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup } from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'
import imageCarouselStyles from './imageCarousel.module.css'
import Arrow from '../../../../../../../static/svgs/arrowdown.svg'
import { injectIntl } from '../../../../../../../plugins/gatsby-plugin-intl-custom'

/*Props
images
amountOfSlides
*/

class ImageCarousel extends React.Component {
  render() {
    const { images, amountOfSlides } = this.props

    const slides = images.map((image, index) => (
      <Slide key={index} data-key={index} index={index}>
        <img className={imageCarouselStyles.image} alt={localizeObject(image.alternativeText, this.props.intl.locale)} src={image.url} loading="lazy" />
      </Slide>
    ))

    return (
      <CarouselProvider className={imageCarouselStyles.container} images={images} naturalSlideHeight="441" naturalSlideWidth="794" totalSlides={amountOfSlides} infinite={true}>
        <Slider>{slides}</Slider>
        <ButtonBack className={imageCarouselStyles.buttonBack}>
          <Arrow className={imageCarouselStyles.arrowLeft} />
        </ButtonBack>
        <ButtonNext className={imageCarouselStyles.buttonNext}>
          <Arrow className={imageCarouselStyles.arrowRight} />
        </ButtonNext>
        <DotGroup className={imageCarouselStyles.bulletWrapper} showAsSelectedForCurrentSlideOnly={true} />
      </CarouselProvider>
    )
  }
}

export default injectIntl(ImageCarousel)
