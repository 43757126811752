import React from 'react'
import CheckIcon from '../../../../../../../static/svgs/check.svg'
import fonts from '../../../../../../styles/fonts.module.css'
import sideBarStyles from '../venueSideBar.module.css'
import { useIntl } from '../../../../../../../plugins/gatsby-plugin-intl-custom'

const VenueTechAndEquipments = () => {
  const intl = useIntl()

  return (
    <div className={sideBarStyles.tableConfigurations}>
      {/*<p className={sideBarStyles.sideBarHeader}>{intl.formatMessage({ id: 'venuePage.techAndEquipments.header'})}</p>*/}
      <p className={fonts.basicParagraph + ' ' + fonts.bold}>{intl.formatMessage({ id: 'venuePage.techAndEquipments.description' })}</p>
      <ul className={sideBarStyles.checkList}>
        <li className={sideBarStyles.checkListItem}>
          <CheckIcon className={sideBarStyles.checkListIcon} />
          <span>{intl.formatMessage({ id: 'venuePage.techAndEquipments.equipments.projector' })}</span>
        </li>
        <li className={sideBarStyles.checkListItem}>
          <CheckIcon className={sideBarStyles.checkListIcon} />
          <span>{intl.formatMessage({ id: 'venuePage.techAndEquipments.equipments.wifi' })}</span>
        </li>
        <li className={sideBarStyles.checkListItem}>
          <CheckIcon className={sideBarStyles.checkListIcon} />
          <span>{intl.formatMessage({ id: 'venuePage.techAndEquipments.equipments.board' })}</span>
        </li>
        <li>
          <CheckIcon className={sideBarStyles.checkListIcon} />
          <span>{intl.formatMessage({ id: 'venuePage.techAndEquipments.equipments.notes' })}</span>
        </li>
      </ul>
    </div>
  )
}

export default VenueTechAndEquipments
