import React from 'react'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import { changeAvailabilityQueryStart, changeVenueAvailability } from '../../state/reduxApp'
import { connect } from 'react-redux'
import { isBackendDateValid } from '../../services/timeService'
import { parseDateToBackendFormat, parseBackendDateToDate } from '../../services/timeService'
import moment from 'moment'

/*
    Props:
      roomId
      onlineReservable
*/
class AvailabilitySingleRoom extends React.Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props)
  }

  render() {
    // Empty venueAvailability which serves as disabling/loading info for other components
    this.props.dispatchVenueAvailability(null)
    if (!this.props.onlineReservable) return null
    if (!this.props.roomId) return null

    /* Pasi 8.1.2020
       Manipulate search to pass searchService tests, which are designed to be used in home page.
       In other words e.g. date is disabled, when boxText is missing. And boxText is missing when http://localhost:8000/?establishmentId=522856699 url is used because it sets only venueId and MeetingRoomsQuery fetches hotelName */
    let clonedSearch = { ...this.props.reduxSearch }
    clonedSearch.boxText = 'AvailabilitySingleRoom'
    clonedSearch.term = 'AvailabilitySingleRoom'

    const venueID = this.props.roomId
    let startDate = this.props.availabilityQueryStart
    if (!isBackendDateValid(startDate)) {
      // If redux day missing then dispatch currentDay+2 to redux so that venuePage fetches initial availability before user interaction
      this.props.dispatchChangeAvailabilityQueryStart(parseDateToBackendFormat(moment().add(2, 'day')))
      return null
    }

    let endDate = parseDateToBackendFormat(moment(parseBackendDateToDate(startDate)).endOf('month'))

    return (
      <>
        <Query query={AVAILABILITY_QUERY} variables={{ venueID, startDate, endDate }} fetchPolicy="network-only" errorPolicy="all">
          {({ data, loading, error }) => {
            if (loading) return null

            if (error) {
              this.props.dispatchVenueAvailability({ error: true })
              return null
            }

            if (!data.venueAvailability || data.venueAvailability.available < 1) {
              this.props.dispatchVenueAvailability({ available: null })
              return null
            }

            this.props.dispatchVenueAvailability(data.venueAvailability)
            return null
          }}
        </Query>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    reduxSearch: state.search,
    availabilityQueryStart: state.availabilityQuery.queryStart,
  }
}

const mapDispatchToProps = dispatch => ({
  dispatchChangeAvailabilityQueryStart: callbackLink => dispatch(changeAvailabilityQueryStart(callbackLink)),
  dispatchVenueAvailability: callbackLink => dispatch(changeVenueAvailability(callbackLink)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AvailabilitySingleRoom)

const AVAILABILITY_QUERY = gql`
  query AvailabilitySingleRoom($venueID: ID!, $startDate: String!, $endDate: String!) {
    venueAvailability(input: { venueID: $venueID, dateInput: { start: $startDate, end: $endDate }, minimumIntervalInMin: 120 }) {
      venueId
      available {
        date
        status
        timeSlots {
          start
          status
        }
      }
    }
  }
`
