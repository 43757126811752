import React, { Component } from 'react'
import fonts from '../../../../../../../../styles/fonts.module.css'
import popupStyles from '../../popup/popup.module.css'
import allergiesPopupStyles from './allergiesPopupStyles.module.css'
import TextArea from '../../../../../../../common/textArea/textArea.js'
import DefaultButton from '../../../../../../../common/defaultButton/defaultButton.js'
import { injectIntl } from '../../../../../../../../../plugins/gatsby-plugin-intl-custom'

/* Props 
cancelClick
*/

class AllergiesPopup extends Component {
  constructor(props) {
    super(props)

    this.state = {
      allergies: this.props.allergies,
      usedCharacters: this.props.allergies.length,
    }

    this.handleOnChange = this.handleOnChange.bind(this)
  }

  componentDidMount() {
    document.body.classList.add('popupOpen')
  }

  componentWillUnmount() {
    document.body.classList.remove('popupOpen')
  }

  handleOnChange(event) {
    this.setState({ allergies: event.target.value, usedCharacters: event.target.value.length })
  }

  render() {
    const allergies = this.state.allergies

    return (
      <div>
        <div className={popupStyles.mask}></div>
        <div className={popupStyles.popUpWindow}>
          <div className={popupStyles.header}>
            <h3 className={fonts.heading3noMargin}>{this.props.intl.formatMessage({ id: 'venuePage.additionalSelections.allergiesTitle' })}</h3>
          </div>
          <div className={popupStyles.body}>
            <div className={allergiesPopupStyles.description}>
              <p className={fonts.basicParagraph}>{this.props.intl.formatMessage({ id: 'venuePage.additionalSelections.allergiesDescription' })}</p>
            </div>
            <TextArea
              id="allergiesTextAreaId"
              value={this.state.allergies}
              labelText={this.props.intl.formatMessage({ id: 'venuePage.additionalSelections.allergiesTitle' })}
              maxCharacters="500"
              usedCharacters={this.state.usedCharacters}
              active={this.state.allergies !== '' ? 1 : 0}
              onChange={this.handleOnChange}
            />
          </div>
          <div className={popupStyles.footer}>
            <div className={popupStyles.footerLeftSection}>
              <DefaultButton type="secondary" buttonText={this.props.intl.formatMessage({ id: 'buttons.cancel' })} onClick={this.props.cancelClick} />
            </div>
            <div className={popupStyles.footerRightSection}>
              <DefaultButton type="primary" buttonText={this.props.intl.formatMessage({ id: 'buttons.save' })} onClick={() => this.props.saveClick(allergies)} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default injectIntl(AllergiesPopup)
