import React, { Component } from 'react'
import { connect } from 'react-redux'
import { parseBackendDateToDDMMYYYY } from '../../../../../../services/timeService'
import { parseTimeSlots } from '../../../../../../services/venueService'
import { personCountValue, starttimeValue, endtimeValue } from '../../../../../../services/searchService'
import Calendar from '../../../../../common/calendar/customCalendar'
import AvailableHoursDisplay from './availableHoursDisplay/availableHoursDisplay'
import StartTimeSelector from '../../../../../search/searchInputs/startTimeSelector/startTimeSelector'
import EndTimeSelector from '../../../../../search/searchInputs/endTimeSelector/endTimeSelector'
import 'react-datepicker/dist/react-datepicker.css'
import venueListItemStyles from '../venueListItemStyles.module.css'
import PersonSelector from '../../../../../common/personCountSelector/personCountSelector'
import PreReservationbutton from '../../../../buttons/preReservationbutton'
import LoadingAnimation from '../../../../../../../static/svgs/loading_animation.svg'
import Notification from '../../../../../common/notification/notification'
import fonts from '../../../../../../styles/fonts.module.css'
import { injectIntl } from '../../../../../../../plugins/gatsby-plugin-intl-custom'

class VenueDatepicker extends Component {
  isSelectedTimeAvailable() {
    if (!this.props.reduxData.venueAvailability || !this.props.reduxReservationDate || !starttimeValue(this.props.reduxSearch) || !endtimeValue(this.props.reduxSearch)) return false
    const date = this.props.reduxData.venueAvailability.available.find(a => a.date === this.props.reduxReservationDate)
    if (!date) return false

    const { isAnySelectedSlotNotFree } = parseTimeSlots(date.timeSlots, this.props.reduxSearch.startTime, this.props.reduxSearch.endTime)
    if (isAnySelectedSlotNotFree) return false

    return true
  }

  render() {
    const { edge } = this.props
    const disabled = !personCountValue(this.props.reduxSearch) || this.props.personCount < edge.minCapacity || this.props.personCount > edge.maxCapacity

    return (
      <div className={venueListItemStyles.dateContainer}>
        <div style={{ width: '200px', marginBottom: '2rem' }}>
          <PersonSelector edge={this.props.edge} onBlur={this.props.handleTableformNotAvailableForPersonCount} />
        </div>
        <div className={venueListItemStyles.calendarComponents}>
          <div className={venueListItemStyles.calendar}>
            <Calendar
              disabled={disabled} //
              changeUserMessage={message => this.props.changeUserMessage(message)} //
              handlePaymentTypeNotAvailable={date => this.props.handlePaymentTypeNotAvailable(date)}
            />
          </div>
          <div className={venueListItemStyles.calendarInfoSection}>
            <div className={venueListItemStyles.freeDaysInfo}>
              <div className={venueListItemStyles.freeDaysInfoBox} />
              {this.props.intl.formatMessage({ id: 'venuePage.date.available' })}
            </div>
            <div className={venueListItemStyles.partlyBookedDaysInfo}>
              <div className={venueListItemStyles.partlyBookedDaysInfoBox} />
              {this.props.intl.formatMessage({ id: 'venuePage.date.partlyAvailable' })}
            </div>
            <div className={venueListItemStyles.bookedDaysInfo}>
              <div className={venueListItemStyles.bookedDaysInfoBox} />
              {this.props.intl.formatMessage({ id: 'venuePage.date.fullyBooked' })}
            </div>
            {edge.phoneNumber && (
              <div className={venueListItemStyles.bookingInfo}>
                <Notification type="info" text={this.props.intl.formatMessage({ id: 'venuePage.date.multiVenueBookingInfo' }) + edge.phoneNumber} visible />
              </div>
            )}
          </div>
        </div>
        <div className={venueListItemStyles.hoursContainer}>
          <p className={fonts.legend}>
            {this.props.intl.formatMessage({ id: 'venuePage.date.availability' })} {this.props.reduxReservationDate && parseBackendDateToDDMMYYYY(this.props.reduxReservationDate)}
          </p>
          <AvailableHoursDisplay disabled={disabled} />
          {this.props.reduxData.venueAvailability && starttimeValue(this.props.reduxSearch) && endtimeValue(this.props.reduxSearch) && !this.isSelectedTimeAvailable() && (
            <div className={venueListItemStyles.notificationWrapper}>
              <Notification type="error" text={this.props.intl.formatMessage({ id: 'venuePage.date.selectedTimeNotAvailable' })} visible />
            </div>
          )}
        </div>
        <div className={venueListItemStyles.inputWrapper}>
          <StartTimeSelector isDisabled={disabled || !this.props.reduxReservationDate} ref={this.startTimeRef} changeUserMessage={message => this.props.changeUserMessage(message)} />
        </div>
        <div className={venueListItemStyles.inputWrapper}>
          <EndTimeSelector isDisabled={disabled || !this.props.reduxReservationDate} changeUserMessage={message => this.props.changeUserMessage(message)} />
        </div>

        <div className={venueListItemStyles.nextButtonWrapper}>
          <PreReservationbutton
            edge={this.props.edge}
            afterPreReservationClick={index => this.props.showNextSection(index)}
            changeUserMessage={message => this.props.changeUserMessage(message)}
            buttonText={this.props.intl.formatMessage({ id: 'venuePage.nextPhase' })}
            disabled={this.props.personCount < edge.minCapacity || this.props.personCount > edge.maxCapacity || !this.isSelectedTimeAvailable()}
          />
        </div>
        <div>{/* <Notification type='info' text={localizations.venuePage.date.minimalBookableTime} visible/> */}</div>
        {!this.props.reduxData.venueAvailability && (
          <div className={venueListItemStyles.loadingAnimationWrapper}>
            <LoadingAnimation className={venueListItemStyles.loadingAnimation} />
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    reduxData: state.data,
    language: state.language,
    reduxSearch: state.search,
    reduxReservationDate: state.search.reservationDate,
    searchBoxText: state.search.boxText,
    personCount: state.search.personCount,
  }
}

export default connect(mapStateToProps, null)(injectIntl(VenueDatepicker))
