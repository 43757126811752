import React, { Component } from 'react'
import { connect } from 'react-redux'
import { changeReservationDate, changeAvailabilityQueryStart } from '../../../state/reduxApp'
import { parseBackendDateToMoment, parseMomentToBackendFormat } from '../../../services/timeService'
import DayNames from './dayNames/dayNames'
import ArrowDown from '../../../../static/svgs/arrowdown.svg'
import calendarStyles from './calendar.module.css'
import { trackFilterEvent } from '../../../services/analyticsService'
import { injectIntl } from '../../../../plugins/gatsby-plugin-intl-custom'
import * as moment from 'moment'
import 'moment/locale/fi.js'
import 'moment/locale/et.js'
import 'moment/locale/sv.js'
import 'moment/locale/en-gb.js'

/*  Props
      -disabled
      -changeUserMessage
      -handlePaymentTypeNotAvailable
*/
class CustomCalendar extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selected: moment()
        .add(2, 'day')
        .locale(this.props.intl.locale),
      firstBookableDay: moment().add(1, 'day'),
      lastBookableDay: moment().add(18, 'M'), // the last day allowed to make bookings is set in back-end to be 1,5 year from today
    }

    this.handlePreviousMonthClick = this.handlePreviousMonthClick.bind(this)
    this.handleNextMonthClick = this.handleNextMonthClick.bind(this)
    this.selectDay = this.selectDay.bind(this)
    this.handlePreviousMonthOnKeyPressed = this.handlePreviousMonthOnKeyPressed.bind(this)
    this.handleNextMonthOnKeyPressed = this.handleNextMonthOnKeyPressed.bind(this)
  }

  componentDidMount() {
    if (!this.props.reduxReservationDate) {
      this.props.dispatchReservationDate(parseMomentToBackendFormat(this.state.selected))
      return
    }

    let momentDate = parseBackendDateToMoment(this.props.reduxReservationDate).locale(this.props.intl.locale)
    if (!momentDate) return
    this.setState({
      selected: momentDate,
    })

    if (!momentDate.isSame(moment(), 'month')) {
      const startOfMonth = momentDate
        .clone()
        .startOf('month')
        .locale(this.props.intl.locale)
      this.props.dispatchChangeAvailabilityQueryStart(parseMomentToBackendFormat(startOfMonth))
    }
  }

  /* #region functions */
  handlePreviousMonthClick() {
    let newDay = this.state.selected
      .clone()
      .subtract(1, 'month')
      .startOf('month')
      .startOf('day')
    // Don't allow newDay to be before current day
    if (newDay.isBefore(this.state.firstBookableDay))
      newDay = moment()
        .add(2, 'day')
        .startOf('day')

    const newDayBackendFormat = parseMomentToBackendFormat(newDay)
    this.props.dispatchChangeAvailabilityQueryStart(newDayBackendFormat)
    this.props.dispatchReservationDate(newDayBackendFormat)
    this.setState(
      {
        selected: newDay,
      },
      () => this.props.handlePaymentTypeNotAvailable(newDayBackendFormat)
    )
    this.props.changeUserMessage(null)
  }

  handleNextMonthClick() {
    const newDay = this.state.selected
      .clone()
      .add(1, 'month')
      .startOf('month')
      .startOf('day')
    if (!newDay.isBefore(this.state.lastBookableDay)) return

    const newDayBackendFormat = parseMomentToBackendFormat(newDay)
    this.props.dispatchChangeAvailabilityQueryStart(newDayBackendFormat)
    this.props.dispatchReservationDate(newDayBackendFormat)
    this.setState(
      {
        selected: newDay,
      },
      () => this.props.handlePaymentTypeNotAvailable(newDayBackendFormat)
    )
    this.props.changeUserMessage(null)
  }

  handlePreviousMonthOnKeyPressed(e) {
    e.preventDefault()
    if (e.charCode === 13) {
      this.handlePreviousMonthClick()
    }
  }

  handleNextMonthOnKeyPressed(e) {
    e.preventDefault()
    if (e.charCode === 13) {
      this.handleNextMonthClick()
    }
  }

  selectDay(day, availabilityStatus) {
    if (this.dayDisabled(day)) return
    if (availabilityStatus === 'Booked' || availabilityStatus === 'Unavailable') return
    const dayBackendFormat = parseMomentToBackendFormat(day)
    if (!dayBackendFormat) return

    this.props.dispatchReservationDate(dayBackendFormat)
    this.setState(
      {
        selected: day,
      },
      () => this.props.handlePaymentTypeNotAvailable(dayBackendFormat)
    )
    this.props.changeUserMessage(null)
    trackFilterEvent('date', day.toString())
  }

  getAvailabilityStatus(day) {
    if (!this.props.reduxVenueAvailability) return ''
    if (this.props.reduxVenueAvailability.error) return ''
    if (!this.props.reduxVenueAvailability.available) return ''

    let availability = this.props.reduxVenueAvailability.available.find(item => item.date === parseMomentToBackendFormat(day))

    if (!availability) return ''
    return availability.status
  }

  getAvailabilityClassname(availabilityStatus) {
    switch (availabilityStatus) {
      case 'Booked':
        return calendarStyles.dayFullyBooked
      case 'Unavailable':
        return calendarStyles.dayFullyBooked
      case 'PartialyBooked':
        return calendarStyles.dayPartlyBooked
      case 'Free':
        return calendarStyles.dayAvailable
      default:
        return calendarStyles.passDay
    }
  }

  handleKeyPressed(e, day, availabilityStatus) {
    e.preventDefault()
    // if user presses enter key the day gets selected
    if (e.charCode === 13) this.selectDay(day, availabilityStatus)
  }

  dayDisabled(day) {
    if (moment(day).isBefore(this.state.firstBookableDay)) return true
    if (moment(day).isAfter(this.state.lastBookableDay)) return true
    else return false
  }
  /* #endregion */

  /* #region partial renderers */
  renderMonthLabel() {
    return (
      <span className={calendarStyles.monthLabel}>
        {this.state.selected.format('MMMM')}
        <br />
        <span className={calendarStyles.monthLabelYear}>{this.state.selected.format('YYYY')}</span>
      </span>
    )
  }

  renderWeeks() {
    let weeks = []
    for (let index = 0; index < 6; index++) {
      const startDayOfWeek = this.state.selected
        .clone()
        .startOf('month')
        .add(index, 'w')
        .isoWeekday(1)

      // Don't render, if week's startday is not anymore in the same month
      if (index > 3 && !this.state.selected.isSame(startDayOfWeek, 'month')) break
      weeks.push(this.renderWeek(startDayOfWeek))
    }
    return weeks
  }

  renderWeek(startDay) {
    let days = []
    for (var i = 0; i < 7; i++) {
      let loopDay = startDay.clone().add(i, 'day')
      days.push(this.renderDay(loopDay))
    }
    return (
      <div className={[calendarStyles.row, calendarStyles.week].join(' ')} key={'w' + startDay.week()}>
        {days}
      </div>
    )
  }

  renderDay(day) {
    const availabilityStatus = this.getAvailabilityStatus(day)

    return (
      <span
        tabIndex="0"
        role="gridcell"
        key={day.toString()}
        className={[
          calendarStyles.day,
          this.getAvailabilityClassname(availabilityStatus),
          day.isSame(moment(), 'day') && calendarStyles.today,
          !day.isSame(this.state.selected, 'month') && calendarStyles.differentMonth,
          day.isSame(this.state.selected, 'day') && calendarStyles.selected,
        ].join(' ')}
        onClick={() => this.selectDay(day, availabilityStatus)}
        onKeyPress={e => this.handleKeyPressed(e, day, availabilityStatus)}
      >
        {day.date().toString()}
      </span>
    )
  }

  /* #endregion */

  render() {
    return (
      <section className={calendarStyles.calendar}>
        <header className={calendarStyles.header}>
          <div className={[calendarStyles.monthDisplay, calendarStyles.row].join(' ')}>
            <ArrowDown
              className={[calendarStyles.arrow, calendarStyles.arrowLeft].join(' ')}
              onClick={this.handlePreviousMonthClick}
              tabIndex="0"
              onKeyPress={e => this.handlePreviousMonthOnKeyPressed(e)}
            />
            {this.renderMonthLabel()}
            <ArrowDown
              className={[calendarStyles.arrow, calendarStyles.arrowRight].join(' ')} //
              onClick={this.handleNextMonthClick} //
              tabIndex="0" //
              onKeyPress={e => this.handleNextMonthOnKeyPressed(e)}
            />
          </div>
          <DayNames month={this.state.selected} />
        </header>
        {this.renderWeeks()}
        {this.props.disabled && <div className={calendarStyles.disabled}></div>}
      </section>
    )
  }
}

const mapStateToProps = state => {
  return {
    reduxReservationDate: state.search.reservationDate,
    reduxVenueAvailability: state.data.venueAvailability,
  }
}

const mapDispatchToProps = dispatch => ({
  dispatchReservationDate: callbackLink => dispatch(changeReservationDate(callbackLink)),
  dispatchChangeAvailabilityQueryStart: callbackLink => dispatch(changeAvailabilityQueryStart(callbackLink)),
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CustomCalendar))
