import React, { Component } from 'react'
import calendarStyles from '../calendar.module.css'

class DayNames extends Component {
  render() {
    return (
      <div className={[calendarStyles.row, calendarStyles.dayNames].join(' ')}>
        <span className={calendarStyles.dayName}>{this.props.month._locale._weekdaysShort[1]}</span>
        <span className={calendarStyles.dayName}>{this.props.month._locale._weekdaysShort[2]}</span>
        <span className={calendarStyles.dayName}>{this.props.month._locale._weekdaysShort[3]}</span>
        <span className={calendarStyles.dayName}>{this.props.month._locale._weekdaysShort[4]}</span>
        <span className={calendarStyles.dayName}>{this.props.month._locale._weekdaysShort[5]}</span>
        <span className={calendarStyles.dayName}>{this.props.month._locale._weekdaysShort[6]}</span>
        <span className={calendarStyles.dayName}>{this.props.month._locale._weekdaysShort[0]}</span>
      </div>
    )
  }
}

export default DayNames
