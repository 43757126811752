import React, { Component } from 'react'
import { Link } from 'gatsby'
import { localizeObject } from '../../../../../services/localizationService'
import { differenceInDaysToCurrentDate } from '../../../../../services/timeService'
import { localizeTableConfigurationContent } from '../../../../../services/tableConfigurationService'
import { personCountValue, dateValue, starttimeValue, endtimeValue } from '../../../../../services/searchService'
import { isPrereservationForThisVenue, getTotalPrice, getDateTimeAndPersonCount, getUnitPrice, getPackageUnitsAmount } from '../../../../../services/venueService'
import { isValidBusinessId, isValidCity, isValidCompanyName, isValidEmail, isValidName, isValidPhoneNumber, isValidStreetAddress, isValidZIP } from '../../../../../services/hessuValidationService'
import { connect } from 'react-redux'
import { MEETINGTYPE_SAUNA } from '../../../../../state/reduxApp'
import VenueDatepicker from './venueDatepicker/venueDatepicker'
import VenueTable from './venueTable/venueTable'
import VenuePackageSelection from './venuePackageSelection/venuePackageSelection'
import VenueReservationUserInformation from './venueReservationUserInformation/VenueReservationUserInformation'
import VenueAdditionals from './venueAdditionals/venueAdditionals'
import VenueDescription from './venueDescription/venueDescription'
import RequestForm from '../venueOfferRequestForm/venueOfferRequestForm'
import mainContentStyles from './venueMainContent.module.css'
import PreReservationTimer from '../../../timer/preReservationTimer'
import Notification from '../../../../common/notification/notification'
import ImageCarousel from '../venueMainContent/imageCarousel/imageCarousel'
import accordionStyles from '../../../../common/accordion/accordion.module.css'
import AccordionItem from '../../../../common/accordion/accordionItem/accordionItem'
import fonts from '../../../../../styles/fonts.module.css'
import ArrowLeft from '../../../../../../static/svgs/arrowleft.svg'
import XIcon from '../../../../../../static/svgs/x.svg'
import ConfirmReservationButton from '../../../buttons/confirmReservationButton'
import LoadingMask from '../../../../common/loadingMask/loadingMask'
import CancelPopup from '../venueMainContent/cancelPopup/cancelPopup'
import TimeOutPopup from '../venueMainContent/timeOutPopup/timeOutPopup'
import { injectIntl, FormattedMessage } from '../../../../../../plugins/gatsby-plugin-intl-custom'
import { TYPE_ERROR, TYPE_SUCCESS } from '../../../../../services/messageService'
import {
  changeTableconfigurationType,
  changePaymentType,
  TABLECONFIGURATIONTYPE_ANYTABLEMODEL,
  TABLECONFIGURATIONTYPE_DIPLOMAT,
  TABLECONFIGURATIONTYPE_CLASS_SHAPE,
  TABLECONFIGURATIONTYPE_GROUPWORK,
  TABLECONFIGURATIONTYPE_U_SHAPE,
  TABLECONFIGURATIONTYPE_CHAIRROW,
  TABLECONFIGURATIONTYPE_COCKTAIL,
  TABLECONFIGURATIONTYPE_ROUND,
  TABLECONFIGURATIONTYPE_EAT_BUFFET,
  TABLECONFIGURATIONTYPE_EAT_TABLESERVICE,
} from '../../../../../state/reduxApp'

// TODO: replace downArrow img with imported component
// import ArrowDown from "../../../../static/svgs/arrowdown.svg"
class venueMainContent extends Component {
  // A state of accordion when user clicks to expand it.
  // There can be only one expandedEnabled accordion at
  // a time.
  expandedEnabled = {
    expanded: true,
    disabled: false,
  }

  // Accordios that are positioned after expandedEnabled
  // accordion get this state. For example if user has
  // expanded tableform accordion, userInfo and additionals
  // accordions will be collapsed and disabled (i.e not clickable)
  collapsedDisabled = {
    expanded: false,
    disabled: true,
  }

  // Accordions that are positioned before expandedEnabled accordion
  // get this state. For example if user has expanded tableform
  // accordion, datePicker and packages accordions will be collapsed
  // but enabled (can be expanded by clicking on accordion)
  collapsedEnabled = {
    expanded: false,
    disabled: false,
  }

  constructor(props) {
    super(props)

    this.state = {
      desc: localizeObject(this.props.edge.longDescription, this.props.intl.locale).trim(),
      fadeIn: true,
      sidebarHeight: 0,
      userMessage: null,
      timeOutPopupOpen: false,
      cancelPopupOpen: false,
      packageID: '',
      packageName: '',
      allergies: '',
      eventTitle: '',
      eventDescription: '',
      additionalRequirements: '',
      refreshments: [],
      termsAccepted: false,
      languageNotificationShown: true,

      accordionsState: {
        DATE_PICKER: this.expandedEnabled,
        PACKAGES: this.collapsedDisabled,
        TABLE_FORM: this.collapsedDisabled,
        USER_INFO: this.collapsedDisabled,
        ADDITIONALS: this.collapsedDisabled,
      },
    }

    // Used in reservation accordion
    this.dateRef = React.createRef()
    this.tableformRef = React.createRef()
    this.packagesRef = React.createRef()
    this.userRef = React.createRef()
    this.additionalsRef = React.createRef()

    this.openConfirmPopup = this.openConfirmPopup.bind(this)
    this.changeUserMessage = this.changeUserMessage.bind(this)
    this.changePackageInformation = this.changePackageInformation.bind(this)
    this.toggleFadeIn = this.toggleFadeIn.bind(this)
    this.saveAllergies = this.saveAllergies.bind(this)
    this.saveRefreshments = this.saveRefreshments.bind(this)
    this.saveAdditionalInfo = this.saveAdditionalInfo.bind(this)
    this.toggleCancelPopup = this.toggleCancelPopup.bind(this)
    this.toggleTimeOutPopup = this.toggleTimeOutPopup.bind(this)
    this.isUserInformationValid = this.isUserInformationValid.bind(this)
    this.toggleAcceptTerms = this.toggleAcceptTerms.bind(this)
    this.handleTableformNotAvailableForPersonCount = this.handleTableformNotAvailableForPersonCount.bind(this)
    this.handlePaymentTypeNotAvailable = this.handlePaymentTypeNotAvailable.bind(this)
    this.scrollToReservationSection = this.scrollToReservationSection.bind(this)
    this.setContainerMinHeight = this.setContainerMinHeight.bind(this)
    this.debouncedSetContainerMinHeight = this.debounce(() => {
      this.setContainerMinHeight()
    }, 50)
    this.handleShowAccordionClick = this.handleShowAccordionClick.bind(this)
    this.isDateTimePickerValid = this.isDateTimePickerValid.bind(this)
  }

  handleShowAccordionClick = (selectedAccordionName, isValid, showNextSection) => {
    try {
      if (!selectedAccordionName) throw new Error('selectedAccordionName: [' + selectedAccordionName + '] is missing.')
    } catch (e) {
      console.error(e.name + ': ' + e.message)
    }

    const { accordionsState } = this.state

    const selectedAccordion = accordionsState[selectedAccordionName]
    try {
      if (!selectedAccordion) throw new Error('failed to map accordion to selectedAccordionName : [' + selectedAccordionName + ']')
    } catch (e) {
      console.error(e.name + ': ' + e.message)
    }

    // If accordion is already expanded, do nothing
    if (selectedAccordion.expanded) return

    // Enforces moving forward using only showNextSection button. If user
    // clicks directly on accordion and it's disabled, do nothing.
    if (!showNextSection && selectedAccordion.disabled) return

    // Validate that fields from previous accordion are filled
    if (!isValid()) return

    const updatedAccordionsState = { ...accordionsState }
    const accordionNames = Object.keys(updatedAccordionsState)
    const selectedAccordionIndex = accordionNames.indexOf(selectedAccordionName)

    accordionNames.forEach((accordionName, accordionIndex) => {
      if (accordionIndex < selectedAccordionIndex) updatedAccordionsState[accordionName] = this.collapsedEnabled
      if (accordionIndex === selectedAccordionIndex) updatedAccordionsState[accordionName] = this.expandedEnabled
      if (accordionIndex > selectedAccordionIndex) updatedAccordionsState[accordionName] = this.collapsedDisabled
    })

    this.setState({ accordionsState: updatedAccordionsState }, () => this.scrollToReservationSection(selectedAccordionName))
  }

  scrollToReservationSection(accordionName) {
    if (!accordionName) return
    let ref = null

    switch (accordionName) {
      case 'DATE_PICKER':
        ref = this.dateRef
        break
      case 'PACKAGES':
        ref = this.packagesRef
        break
      case 'TABLE_FORM':
        ref = this.tableformRef
        break
      case 'USER_INFO':
        ref = this.userRef
        break
      case 'ADDITIONALS':
        ref = this.additionalsRef
        break
      default:
        ref = null
    }

    if (ref && ref.current) window.scrollTo(0, ref.current.getBoundingClientRect().top + window.scrollY)
  }

  isDateTimePickerValid() {
    const { search } = this.props.reduxState

    if (!dateValue(search)) return false
    if (!starttimeValue(search)) return false
    if (!endtimeValue(search)) return false
    if (!personCountValue(search)) return false
    return true
  }

  toggleAcceptTerms() {
    const accepted = this.state.termsAccepted
    this.setState({ termsAccepted: !accepted })
  }

  // TODO: Add type error / success
  changeUserMessage(message) {
    this.setState({ userMessage: message })
  }

  changePackageInformation(passedPackageID, passedPackageName) {
    if (this.state.packageID !== passedPackageID && this.state.packageName !== passedPackageName) {
      this.setState({ packageID: passedPackageID })
      this.setState({ packageName: passedPackageName })
    }
  }

  renderVenueImages(images) {
    const amountOfImages = images.length
    // TODO: if there are no images at all, should there be a placeholder image?
    if (!images || images.length < 1) return null
    if (images.length === 1) return <img className={mainContentStyles.image} alt={localizeObject(images[0].alternativeText, this.props.intl.locale)} src={images[0].url}></img>
    return <ImageCarousel images={this.props.edge.images} amountOfSlides={amountOfImages} />
  }

  // If ru or et user has to be notified that MyPa takes reservations only in fi/sv/en
  renderRuEsLanguageNotification(language) {
    if (language !== 'et') return null
    return (
      <div className={[mainContentStyles.languageNotificationWrapper, this.state.languageNotificationShown ? '' : mainContentStyles.languageNotificationHidden].join(' ')}>
        <div className={mainContentStyles.languageNotification}>
          {language === 'et' && <p className={fonts.smallParagraphNoMargin}>Broneeringute käsitlemine inglise, soome ja rootsi keeles.</p>}
          <XIcon
            className={mainContentStyles.languageNotificationClear}
            onClick={() => {
              let languageNotificationShown = this.state.languageNotificationShown
              this.setState({ languageNotificationShown: !languageNotificationShown })
            }}
          />
        </div>
      </div>
    )
  }

  // This one checks if the Venues long description is over 300 char long and if so sets the state of fadeIn to true. After that fadeIn: true will add some additional styling to hide most of the long description and adds the white gradient to the bottom of the description */
  componentDidMount() {
    this.setContainerMinHeight()
    window.addEventListener('resize', this.debouncedSetContainerMinHeight)

    if (this.state.desc > 300) {
      this.setState({ fadeIn: true })
    }

    window.addEventListener('beforeunload', this.openConfirmPopup)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debouncedSetContainerMinHeight)
    window.removeEventListener('beforeunload', this.openConfirmPopup)
  }

  debounce(func, delay) {
    // TODO: Refactor
    let debouncing
    return () => {
      clearTimeout(debouncing)
      debouncing = setTimeout(() => func.apply(this, arguments), delay)
    }
  }

  setContainerMinHeight() {
    const sidebar = document.getElementById('sidebar')
    if (!sidebar) return null
    this.setState({ sidebarHeight: sidebar.offsetHeight })
  }

  toggleFadeIn() {
    const fadeIn = this.state.fadeIn
    this.setState({ fadeIn: !fadeIn })
  }

  toggleCancelPopup() {
    const open = this.state.cancelPopupOpen
    this.setState({ cancelPopupOpen: !open })
  }

  toggleTimeOutPopup() {
    const open = this.state.timeOutPopupOpen
    this.setState({ timeOutPopupOpen: !open })
  }

  afterCancelReservationError() {
    // TODO: Should timer be hidden if cancelling prereservation fails?
    this.toggleCancelPopup()
  }

  saveAllergies(allergies) {
    this.setState({ allergies: allergies })
  }

  saveRefreshments(venueSelectedRefreshments) {
    this.setState({ refreshments: venueSelectedRefreshments.selectedRefreshments })
  }

  saveAdditionalInfo(title, description, other) {
    this.setState({ eventTitle: title, eventDescription: description, additionalRequirements: other })
  }

  renderLoadingMask() {
    if (this.state.timeOutPopupOpen) return null
    if (this.state.cancelPopupOpen) return null
    if (this.props.reduxWaitingForMutationResponse) return <LoadingMask />
  }

  isUserInformationValid() {
    const { reduxUser, reduxContactPerson } = this.props

    if (!isValidName(reduxUser.firstname)) return false
    if (!isValidName(reduxUser.lastname)) return false
    if (!isValidPhoneNumber(reduxUser.phone)) return false
    if (!isValidEmail(reduxUser.email)) return false
    if (reduxUser.companyName && !isValidCompanyName(reduxUser.companyName)) return false
    if (!reduxUser.companyName && reduxUser.VATnumber) return false
    if (reduxUser.VATnumber && !isValidBusinessId(reduxUser.VATnumber)) return false
    if (!isValidStreetAddress(reduxUser.streetAddress)) return false
    if (!isValidZIP(reduxUser.zipCode)) return false
    if (!isValidCity(reduxUser.city)) return false

    if (!reduxContactPerson.hasContactPerson) return true

    if (!isValidName(reduxContactPerson.firstname)) return false
    if (!isValidName(reduxContactPerson.lastname)) return false
    if (!isValidPhoneNumber(reduxContactPerson.phone)) return false
    if (!isValidEmail(reduxContactPerson.email)) return false

    return true
  }

  handleTableformNotAvailableForPersonCount() {
    if (!this.props.reduxTableform || !this.props.reduxPersonCount) return
    let tableformName = undefined
    // TODO: move to service
    switch (this.props.reduxTableform) {
      case TABLECONFIGURATIONTYPE_DIPLOMAT:
        tableformName = 'Diplomat'
        break
      case TABLECONFIGURATIONTYPE_CLASS_SHAPE:
        tableformName = 'Class_Shape'
        break
      case TABLECONFIGURATIONTYPE_GROUPWORK:
        tableformName = 'GroupWork'
        break
      case TABLECONFIGURATIONTYPE_U_SHAPE:
        tableformName = 'U_Shape'
        break
      case TABLECONFIGURATIONTYPE_CHAIRROW:
        tableformName = 'ChairRow'
        break
      case TABLECONFIGURATIONTYPE_COCKTAIL:
        tableformName = 'Cocktail'
        break
      case TABLECONFIGURATIONTYPE_ROUND:
        tableformName = 'Round'
        break
      case TABLECONFIGURATIONTYPE_EAT_BUFFET:
        tableformName = 'Eat_Buffet'
        break
      case TABLECONFIGURATIONTYPE_EAT_TABLESERVICE:
        tableformName = 'Eat_Tableservice'
        break
      case TABLECONFIGURATIONTYPE_ANYTABLEMODEL:
        tableformName = undefined
        break
      default:
        tableformName = undefined
    }

    const selectedTableform = this.props.edge.tableConfigurations.find(item => {
      if (item.tableConfigurationName === tableformName) return item
      return null
    })

    if (!selectedTableform || (selectedTableform.valueRange.max && selectedTableform.valueRange.max < this.props.reduxPersonCount)) {
      this.props.dispatchTableconfigurationType(null)
    }
  }

  handlePaymentTypeNotAvailable(date) {
    if (!this.props.reduxPaymentType || !date) return
    if (this.props.reduxPaymentType !== 'Prepayed') return

    let diff = differenceInDaysToCurrentDate(date)
    if (diff < 14) this.props.dispatchPaymentType('PaymentOnSite')
  }

  renderTimer() {
    if (!isPrereservationForThisVenue(this.props.reduxConfirmReservation, this.props.reduxPreReservation, this.props.edge)) return null
    return (
      <PreReservationTimer
        afterTimeOut={this.toggleTimeOutPopup}
        totalPrice={getTotalPrice(this.state.packageID, this.props.reduxVenueProducts, this.props.reduxPersonCount, this.state.refreshments)}
        totalText={this.props.intl.formatMessage({ id: 'venuePage.preReservation.total' })}
        preReservationValidText={this.props.intl.formatMessage({ id: 'venuePage.preReservation.validTill' })}
        minText={this.props.intl.formatMessage({ id: 'venuePage.preReservation.minutes' })}
        buttonText={this.props.intl.formatMessage({ id: 'venuePage.preReservation.cancel' })}
        toggleCancelPopup={() => this.toggleCancelPopup()}
      />
    )
  }

  openConfirmPopup(e) {
    if (this.props.reduxPreReservation) {
      var confirmationMessage = ''
      // Some browsers require either return string value or set it as returnValue property

      ;(e || window.event).returnValue = confirmationMessage //Gecko + IE
      return confirmationMessage // Gecko + Webkit, Safari, Chrome etc.
    }
  }
  /* #endregion */

  /* #region partial renderers */
  renderPopups = () => {
    const { edge } = this.props
    return (
      <>
        {this.state.cancelPopupOpen && (
          <CancelPopup
            messageBody={this.props.intl.formatMessage({ id: 'venuePage.preReservation.cancelInfo' })}
            messageHeading={this.props.intl.formatMessage({ id: 'venuePage.preReservation.wannaCancel' })}
            cancelButtonText={this.props.intl.formatMessage({ id: 'venuePage.preReservation.cancel' })}
            notAfterAllButtonText={this.props.intl.formatMessage({ id: 'venuePage.preReservation.notAfterAll' })}
            closeClick={this.toggleCancelPopup}
            changeUserMessage={message => this.changeUserMessage(message)}
            afterCancelReservationSuccess={this.toggleCancelPopup}
            userMessage={this.state.userMessage}
          />
        )}
        {this.state.timeOutPopupOpen && (
          <TimeOutPopup
            heading={this.props.intl.formatMessage({ id: 'venuePage.preReservation.wannaContinue' })}
            description={this.props.intl.formatMessage({ id: 'venuePage.preReservation.reservationEnded' })}
            noThanxText={this.props.intl.formatMessage({ id: 'venuePage.preReservation.noThanx' })}
            continueText={this.props.intl.formatMessage({ id: 'venuePage.preReservation.continue' })}
            userMessage={this.state.userMessage}
            closeClick={this.toggleTimeOutPopup}
            edge={edge}
            changeUserMessage={message => this.changeUserMessage(message)}
            afterRefreshPreReservation={this.toggleTimeOutPopup}
          />
        )}
      </>
    )
  }

  renderBackLink = () => {
    let pathUrl
    if (this.props.reduxLanguage === 'fi') {
      pathUrl = '/'
    }
    if (this.props.reduxLanguage === 'sv') {
      pathUrl = '/sv'
    }
    if (this.props.reduxLanguage === 'en') {
      pathUrl = '/en'
    }
    if (this.props.reduxLanguage === 'et') {
      pathUrl = '/et'
    }

    return (
      <>
        <div className={mainContentStyles.backLinkContainer}>
          <Link to={pathUrl} className={fonts.textLink} state={this.props.reduxState}>
            <ArrowLeft className={mainContentStyles.arrowLeft} />
            <span>{this.props.intl.formatMessage({ id: 'search.linkToSearchPage' })}</span>
          </Link>
        </div>
      </>
    )
  }

  renderNotification = accordionName => {
    if (!this.state.userMessage) return null

    let messageType

    switch (this.state.userMessage.type) {
      case TYPE_ERROR:
        messageType = 'error'
        break
      case TYPE_SUCCESS:
        messageType = 'success'
        break
      default:
        messageType = 'error'
    }

    return <Notification type={messageType} text={this.state.userMessage.body} visible={this.state.userMessage.visibleForPrereservation && this.state.accordionsState[accordionName].expanded} />
  }

  accordionComponent(accordionName, nextAccordionName, isValidFunc) {
    const { edge } = this.props

    switch (accordionName) {
      case 'DATE_PICKER':
        return (
          <VenueDatepicker
            edge={edge}
            showNextSection={() => this.handleShowAccordionClick(nextAccordionName, isValidFunc, true)}
            changeUserMessage={message => this.changeUserMessage(message)}
            handleTableformNotAvailableForPersonCount={this.handleTableformNotAvailableForPersonCount}
            handlePaymentTypeNotAvailable={this.handlePaymentTypeNotAvailable}
          />
        )
      case 'PACKAGES':
        return (
          <VenuePackageSelection
            edge={edge}
            showNextSection={() => this.handleShowAccordionClick(nextAccordionName, isValidFunc, true)}
            changeUserMessage={message => this.changeUserMessage(message)}
            packageID={this.state.packageID}
            packageName={this.state.packageName}
            changePackageInformation={this.changePackageInformation}
          />
        )
      case 'TABLE_FORM':
        return (
          <VenueTable
            edge={edge}
            showNextSection={() => this.handleShowAccordionClick(nextAccordionName, isValidFunc, true)}
            changeUserMessage={message => this.changeUserMessage(message)}
            handleTableformNotAvailableForPersonCount={this.handleTableformNotAvailableForPersonCount}
            handlePaymentTypeNotAvailable={date => this.handlePaymentTypeNotAvailable(date)}
          />
        )
      case 'USER_INFO':
        return (
          <VenueReservationUserInformation //
            edge={edge} //
            showNextSection={() => this.handleShowAccordionClick(nextAccordionName, isValidFunc, true)} //
            changeUserMessage={message => this.changeUserMessage(message)} //
          />
        )
      case 'ADDITIONALS':
        return (
          <VenueAdditionals
            edge={edge}
            changeUserMessage={message => this.changeUserMessage(message)}
            saveAllergies={this.saveAllergies}
            allergies={this.state.allergies}
            saveRefreshments={this.saveRefreshments}
            refreshments={this.state.refreshments}
            saveAdditionalInfo={this.saveAdditionalInfo}
            eventTitle={this.state.eventTitle}
            eventDescription={this.state.eventDescription}
            additionalRequirements={this.state.additionalRequirements}
          />
        )
      default:
        return null
    }
  }

  accordionUniqueProps(accordionName) {
    switch (accordionName) {
      case 'DATE_PICKER':
        return {
          header: this.props.intl.formatMessage({ id: 'venuePage.date.dateHeader' }) + ' *',
          ref: this.dateRef,
          stateFilled: getDateTimeAndPersonCount(this.props.reduxState, this.props.edge),
          isValidFunc: () => {
            return true
          },
        }
      case 'PACKAGES':
        return {
          header: this.props.intl.formatMessage({ id: 'venuePage.package.packageHeader' }) + ' *',
          ref: this.packagesRef,
          stateFilled: this.state.packageName,
          isValidFunc: this.isDateTimePickerValid,
        }
      case 'TABLE_FORM':
        return {
          header: this.props.intl.formatMessage({ id: 'venuePage.personTable.personTableHeader' }) + ' *',
          ref: this.tableformRef,
          stateFilled: localizeTableConfigurationContent(this.props.reduxState.search.tableConfigurationType, this.props.intl.locale),
          isValidFunc: () => {
            return this.state.packageID
          },
        }
      case 'USER_INFO':
        return {
          header: this.props.intl.formatMessage({ id: 'venuePage.reservationInfo.reservationInfoHeader' }) + ' *',
          ref: this.userRef,
          stateFilled: this.isUserInformationValid(),
          isValidFunc: () => {
            // Table form is not selectable when reserving saunas, so validator must only verify that package was selected
            if (this.props.meetingType === MEETINGTYPE_SAUNA) return this.state.packageID
            return this.props.reduxTableform
          },
        }
      case 'ADDITIONALS':
        return {
          header: this.props.intl.formatMessage({ id: 'venuePage.additionalInfo.additionalInfoHeader' }),
          ref: this.additionalsRef,
          stateFilled: '',
          isValidFunc: this.isUserInformationValid,
        }
      default:
        return ''
    }
  }

  renderAccordions = () => {
    if (!this.props.edge.onlineReservable) return null

    const accordionNames = //
      this.props.meetingType === MEETINGTYPE_SAUNA //
        ? ['DATE_PICKER', 'PACKAGES', 'USER_INFO', 'ADDITIONALS'] //
        : ['DATE_PICKER', 'PACKAGES', 'TABLE_FORM', 'USER_INFO', 'ADDITIONALS']

    const accordionItems = accordionNames.map((accordionName, accordionIndex) => {
      const uniqueProps = this.accordionUniqueProps(accordionName)

      let nextAccordionName = accordionIndex < accordionNames.length ? accordionNames[accordionIndex + 1] : null

      return (
        <AccordionItem
          key={accordionName}
          expanded={this.state.accordionsState[accordionName].expanded}
          disabled={this.state.accordionsState[accordionName].disabled}
          index={accordionIndex}
          header={uniqueProps.header}
          ref={uniqueProps.ref}
          stateFilled={uniqueProps.stateFilled}
          onClick={() => this.handleShowAccordionClick(accordionName, uniqueProps.isValidFunc, false)}
        >
          {this.accordionComponent(accordionName, nextAccordionName, uniqueProps.isValidFunc)}

          {this.renderNotification(accordionName)}
        </AccordionItem>
      )
    })

    return (
      <>
        <ul className={accordionStyles.accordion}>{accordionItems}</ul>
      </>
    )
  }
  renderOfflineInfo = () => {
    const { edge } = this.props
    if (edge.onlineReservable) return null
    return (
      <>
        <div>
          <p className={fonts.basicParagraph}>
            <FormattedMessage id="venuePage.offline" /> <span className={fonts.bold}>{edge.phoneNumber !== undefined ? edge.phoneNumber : '+358 300 870 000'}</span>{' '}
            <FormattedMessage id="venuePage.offline2" />
          </p>
        </div>
      </>
    )
  }
  renderOfferRequestForm = () => {
    if (this.props.edge.onlineReservable) return null
    return (
      <>
        <div style={{ padding: '2rem 0 3rem' }}>
          <RequestForm edgeId={this.props.edge.id} />
        </div>
      </>
    )
  }
  /* #endregion */

  render() {
    const { edge } = this.props

    return (
      <div style={{ minHeight: this.state.sidebarHeight }} className={mainContentStyles.mainContent}>
        {this.renderPopups()}
        {this.renderBackLink()}
        <div className={mainContentStyles.imgContainer}>{this.renderVenueImages(edge.images)}</div>
        <div id="bookThisRoom" className={mainContentStyles.venueContainer}>
          <div className={mainContentStyles.venueDescriptionWrapperLarge}>
            <VenueDescription desc={this.state.desc} fadeIn={this.state.fadeIn} toggleFadeIn={this.toggleFadeIn} />
          </div>
          <h2 className={fonts.heading2}>
            <FormattedMessage id="venuePage.bookVenueHeading" /> {localizeObject(edge.name, this.props.intl.locale)}
          </h2>
          {this.renderRuEsLanguageNotification(this.props.intl.locale)}
          {this.renderAccordions()}
          {this.renderOfflineInfo()}
          <ConfirmReservationButton
            edge={edge}
            packageID={this.state.packageID}
            allergies={this.state.allergies}
            eventTitle={this.state.eventTitle}
            eventDescription={this.state.eventDescription}
            additionalRequirements={this.state.additionalRequirements}
            refreshments={this.state.refreshments}
            changeUserMessage={message => this.changeUserMessage(message)}
            toggleAcceptTerms={this.toggleAcceptTerms}
            termsAccepted={this.state.termsAccepted}
            totalPrice={getTotalPrice(this.state.packageID, this.props.reduxVenueProducts, this.props.reduxPersonCount, this.state.refreshments)}
            packageUnitPrice={getUnitPrice(this.state.packageID, this.props.reduxVenueProducts)} // TODO
            packageUnitsAmount={getPackageUnitsAmount(this.state.packageID, this.props.reduxVenueProducts, this.props.reduxPersonCount)}
          />
          {this.renderTimer()}
          {this.renderOfferRequestForm()}
        </div>
        {this.renderLoadingMask()}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    reduxState: state,
    reduxLanguage: state.language,
    reduxVenueProducts: state.data.venueProducts,
    reduxPersonCount: state.search.personCount,
    reduxTableform: state.search.tableConfigurationType,
    meetingType: state.search.meetingType,
    reduxUser: state.user,
    reduxContactPerson: state.contactPerson,
    reduxPaymentType: state.paymentType,
    reduxPreReservation: state.data.createReservation,
    reduxConfirmReservation: state.data.confirmReservation,
    reduxWaitingForMutationResponse: state.queryStatuses.mutatingReservation,
  }
}
const mapDispatchToProps = dispatch => ({
  dispatchTableconfigurationType: callbackLink => dispatch(changeTableconfigurationType(callbackLink)),
  dispatchPaymentType: callbackLink => dispatch(changePaymentType(callbackLink)),
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(venueMainContent))
