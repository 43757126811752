import React from 'react'
import { connect } from 'react-redux'
import { withApollo } from 'react-apollo'
import {
  changeCancelReservation,
  changeCreateReservation,
  changeConfirmReservation,
  setMutatingReservationTrue,
  setMutatingReservationFalse,
  changeAvailabilityQueryStart,
  MEETINGTYPE_PARTY,
  MEETINGTYPE_SAUNA,
  TABLECONFIGURATIONTYPE_DIPLOMAT,
  TABLECONFIGURATIONTYPE_CLASS_SHAPE,
  TABLECONFIGURATIONTYPE_U_SHAPE,
  TABLECONFIGURATIONTYPE_GROUPWORK,
  TABLECONFIGURATIONTYPE_CHAIRROW,
  TABLECONFIGURATIONTYPE_COCKTAIL,
  TABLECONFIGURATIONTYPE_ROUND,
  TABLECONFIGURATIONTYPE_EAT_TABLESERVICE,
  TABLECONFIGURATIONTYPE_EAT_BUFFET,
} from '../../../state/reduxApp'
import gql from 'graphql-tag'
import { getCurrentTime } from '../../../services/timeService'
import { handleReservationMutationError } from '../../../services/messageService'
import { dateValue, starttimeValue, endtimeValue, personCountValue, tableConfigurationValue } from '../../../services/searchService'
import Button from '../../common/defaultButton/defaultButton'
import { trackEvent, trackErrorEvent } from '../../../services/analyticsService'

/*  Props
    -edge
    -changeUserMessage
*/
class PreReservationButton extends React.Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props)
    this.createPreReservation = this.createPreReservation.bind(this)
  }

  /* #region mutations */
  createPreReservation() {
    if (this.props.reduxWaitingForMutationResponse) return

    let eventType = 'Meeting'
    if (this.props.reduxSearch.meetingType === MEETINGTYPE_PARTY) eventType = 'Celebration'
    if (this.props.reduxSearch.meetingType === MEETINGTYPE_SAUNA) eventType = 'Sauna'

    const priceLevelCode = ''
    const venueID = this.props.edge.id
    const date = dateValue(this.props.reduxSearch)
    const startTime = starttimeValue(this.props.reduxSearch)
    const endTime = endtimeValue(this.props.reduxSearch)
    const personCount = personCountValue(this.props.reduxSearch)

    let tableConfiguration = undefined
    switch (tableConfigurationValue(this.props.reduxSearch)) {
      case TABLECONFIGURATIONTYPE_DIPLOMAT:
        tableConfiguration = 'Diplomat'
        break
      case TABLECONFIGURATIONTYPE_CLASS_SHAPE:
        tableConfiguration = 'Class_Shape'
        break
      case TABLECONFIGURATIONTYPE_GROUPWORK:
        tableConfiguration = 'GroupWork'
        break
      case TABLECONFIGURATIONTYPE_U_SHAPE:
        tableConfiguration = 'U_Shape'
        break
      case TABLECONFIGURATIONTYPE_COCKTAIL:
        tableConfiguration = 'Cocktail'
        break
      case TABLECONFIGURATIONTYPE_CHAIRROW:
        tableConfiguration = 'ChairRow'
        break
      case TABLECONFIGURATIONTYPE_EAT_BUFFET:
        tableConfiguration = 'Eat_Buffet'
        break
      case TABLECONFIGURATIONTYPE_EAT_TABLESERVICE:
        tableConfiguration = 'Eat_Tableservice'
        break
      case TABLECONFIGURATIONTYPE_ROUND:
        tableConfiguration = 'Round'
        break
      default:
        tableConfiguration = null
        break
    }

    this.props.dispatchMutatingReservationTrue()
    this.props.changeUserMessage(handleReservationMutationError(undefined))

    this.props.client
      .mutate({
        mutation: CREATE_PRERESERVATION,
        variables: { tableConfiguration, priceLevelCode, eventType, venueID, date, startTime, endTime, personCount },
      })
      .then(result => {
        if (!result.data || !result.data.createReservation) {
          this.props.changeUserMessage(handleReservationMutationError('Missing response data error', this.props.reduxLanguage))
          this.props.dispatchCreateReservation(undefined)
          this.props.dispatchMutatingReservationFalse()

          trackErrorEvent({
            action: 'preReservation',
            label: 'Missing response data',
            value: result,
          })

          return
        }

        // Add timestamp createReservation to be used when showing preservation time left
        result.data.createReservation.creationTime = getCurrentTime()
        this.props.dispatchCreateReservation(result.data.createReservation)
        this.props.dispatchCancelReservation(undefined)
        this.props.dispatchConfirmReservation(undefined)
        this.props.dispatchMutatingReservationFalse()
        this.props.afterPreReservationClick()
        trackEvent({
          event: 'addToCart',
          ecommerce: {
            add: {
              products: result.data.createReservation.venueReservations.map(reservation => ({
                name: this.props.edge.establishment.name.fi_FI,
                id: reservation.venue.id,
                category: reservation.venue.name.fi_FI,
                quantity: 1,
              })),
            },
          },
        })
      })
      .catch(error => {
        this.props.changeUserMessage(handleReservationMutationError(error, this.props.reduxLanguage))
        this.props.dispatchCreateReservation(undefined)
        this.props.dispatchMutatingReservationFalse()
      })
  }
  /* #endregion */

  /* #region renderers */
  renderCreatePreReservationButton() {
    if (this.props.reduxPreReservation)
      return (
        <Button
          disabled={this.props.disabled || this.props.reduxWaitingForMutationResponse}
          isLoadingButton={this.props.reduxWaitingForMutationResponse}
          type="smallPrimary"
          onClick={this.props.afterPreReservationClick}
          buttonText={this.props.buttonText}
        />
      )

    let buttonDisabled = false
    if (!dateValue(this.props.reduxSearch)) buttonDisabled = true
    if (!starttimeValue(this.props.reduxSearch)) buttonDisabled = true
    if (!endtimeValue(this.props.reduxSearch)) buttonDisabled = true
    if (!personCountValue(this.props.reduxSearch)) buttonDisabled = true
    if (this.props.disabled) buttonDisabled = true
    if (this.props.reduxWaitingForMutationResponse) buttonDisabled = true

    return <Button disabled={buttonDisabled} isLoadingButton={this.props.reduxWaitingForMutationResponse} type="smallPrimary" onClick={this.createPreReservation} buttonText={this.props.buttonText} />
  }
  /* #endregion */

  render() {
    return <>{this.renderCreatePreReservationButton()}</>
  }
}

const mapStateToProps = state => {
  return {
    reduxSearch: state.search,
    reduxLanguage: state.language,
    reduxPreReservation: state.data.createReservation,
    reduxWaitingForMutationResponse: state.queryStatuses.mutatingReservation,
    availabilityQueryStart: state.availabilityQuery.queryStart,
  }
}

const mapDispatchToProps = dispatch => ({
  dispatchCancelReservation: callbackLink => dispatch(changeCancelReservation(callbackLink)),
  dispatchCreateReservation: callbackLink => dispatch(changeCreateReservation(callbackLink)),
  dispatchConfirmReservation: callbackLink => dispatch(changeConfirmReservation(callbackLink)),
  dispatchMutatingReservationTrue: callbackLink => dispatch(setMutatingReservationTrue(callbackLink)),
  dispatchMutatingReservationFalse: callbackLink => dispatch(setMutatingReservationFalse(callbackLink)),
  dispatchChangeAvailabilityQueryStart: callbackLink => dispatch(changeAvailabilityQueryStart(callbackLink)),
})

export default withApollo(connect(mapStateToProps, mapDispatchToProps)(PreReservationButton))

/* #region PreReservation queries */
const CREATE_PRERESERVATION = gql`
  mutation createPreReservation(
    $priceLevelCode: String
    $eventType: VenueType!
    $venueID: ID!
    $date: String!
    $startTime: String!
    $endTime: String!
    $personCount: Int!
    $tableConfiguration: TableConfigurationName
  ) {
    createReservation(
      input: {
        priceLevelCode: $priceLevelCode
        eventType: $eventType
        venuesReservation: { date: $date, startTime: $startTime, endTime: $endTime, venueID: $venueID, personCount: $personCount, tableConfiguration: $tableConfiguration }
      }
    ) {
      id
      lastCancellationDate
      venueReservations {
        id
        venue {
          id
          name {
            fi_FI
          }
          hessuID
        }
        period {
          start
          end
        }
      }
    }
  }
`
/* #endregion */
