import React, { Component } from 'react'
import { connect } from 'react-redux'
import popupStyles from '../smallPopup.module.css'
import PreReservationButton from '../../../../buttons/preReservationbutton'
import Button from '../../../../../common/defaultButton/defaultButton'
import fonts from '../../../../../../styles/fonts.module.css'
import Notification from '../../../../../common/notification/notification'
import { TYPE_ERROR, TYPE_SUCCESS } from '../../../../../../services/messageService'

/*
  Props
    heading
    description
    noThanxText
    continueText
    userMessage
    closeClick
    edge
    changeUserMessage
    afterRefreshPreReservation
*/
class TimeOutPopup extends Component {
  componentDidMount() {
    document.body.classList.add('popupOpen')
  }
  componentWillUnmount() {
    document.body.classList.remove('popupOpen')
  }
  renderNotification() {
    if (!this.props.userMessage) return null

    let notificationType

    switch (this.props.userMessage.type) {
      case TYPE_ERROR:
        notificationType = 'error'
        break
      case TYPE_SUCCESS:
        notificationType = 'success'
        break
      default:
        notificationType = 'error'
    }

    return <Notification type={notificationType} text={this.props.userMessage.body} visible />
  }

  render() {
    return (
      <>
        <div className={popupStyles.mask}></div>
        <div className={popupStyles.popup}>
          <h4 className={fonts.heading3noMargin}>{this.props.heading}</h4>
          <p className={fonts.basicParagraph}>{this.props.description}</p>
          {this.renderNotification()}
          <div className={popupStyles.footer}>
            <div className={popupStyles.footerLeft}>
              <Button type="smallSecondary" buttonText={this.props.noThanxText} onClick={this.props.closeClick} disabled={this.props.reduxWaitingForMutationResponse} />
            </div>
            <div className={popupStyles.footerRight}>
              <PreReservationButton
                buttonText={this.props.continueText}
                edge={this.props.edge}
                changeUserMessage={message => this.props.changeUserMessage(message)}
                afterPreReservationClick={this.props.afterRefreshPreReservation}
              />
            </div>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    reduxWaitingForMutationResponse: state.queryStatuses.mutatingReservation,
  }
}

export default connect(mapStateToProps)(TimeOutPopup)
