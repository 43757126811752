import React, { Component } from 'react'
import textAreaStyles from './textArea.module.css'
import fonts from '../../../styles/fonts.module.css'

/* Props

id
labelText
maxCharacters
usedCharacters
onChange
onFocus
onBlur
active
value
name
*/

class TextArea extends Component {
  constructor(props) {
    super(props)

    this.state = {
      hasFocus: false,
    }

    this.onFocus = this.onFocus.bind(this)
    this.onBlur = this.onBlur.bind(this)
  }

  onFocus(event) {
    this.setState({ hasFocus: true })
    event.preventDefault()
  }

  onBlur(event) {
    this.setState({ hasFocus: false })
    event.preventDefault()
  }

  render() {
    const usedCharacters = this.props.usedCharacters

    return (
      <div className={textAreaStyles.textAreaWrapper}>
        <label className={[textAreaStyles.floatingLabel, this.props.active || this.state.hasFocus ? textAreaStyles.transform : ''].join(' ')}>{this.props.labelText}</label>
        <textarea
          className={textAreaStyles.floatingInput}
          value={this.props.value}
          name={this.props.name}
          maxLength={this.props.maxCharacters}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onChange={this.props.onChange}
          active={this.props.active}
        />
        <div className={textAreaStyles.remainingCharacters}>
          <p className={fonts.xSmallParagraphNoMargin}>
            {usedCharacters < 1 ? 0 : usedCharacters}/{this.props.maxCharacters}
          </p>
        </div>
      </div>
    )
  }
}

export default TextArea
