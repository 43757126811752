import React, { Component } from 'react'
import Select from 'react-select'
import textInputStyles from '../../../../../../common/textInput/textInput.module.css'
import ArrowIcon from '../../../../../../../../static/svgs/arrowdown.svg'
import inputIconStyles from '../../../../../../common/inputIconStyles.module.css'
import { injectIntl, FormattedMessage } from '../../../../../../../../plugins/gatsby-plugin-intl-custom'

/*  Props
      -handleOnChange
      -country
*/
class CountryDropdown extends Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props)
  }

  renderArrowIcon() {
    return <ArrowIcon className={inputIconStyles.arrowIcon} />
  }

  render() {
    const countries = [
      { value: 'FI', label: 'Suomi' },
      { value: 'SE', label: 'Ruotsi' },
      { value: 'EE', label: 'Viro' },
      { value: 'NO', label: 'Norja' },
      { value: null, label: 'Muu' },
    ]

    // React-Select component provides it's own API for styling. For more information about custom styles
    // you should check out their site: https://react-select.com/styles
    const customStyles = {
      valueContainer: () => ({
        boxShadow: 'none',
        marginLeft: '0.625rem',
      }),
      control: (base, state) => ({
        ...base,
        height: '2.6rem',
        borderRadius: '3px',
        border: '1px solid #D2D2D2',
        boxShadow: state.isFocused ? '0 0 5px 0 rgba(132,132,132,0.8)' : '',

        '&:hover': {
          border: '1px solid #D2D2D2',
        },
      }),
      input: base => ({
        ...base,
        fontSize: '0.8em',
        paddingTop: '0.9rem',
        boxShadow: 'none',
        color: '#000',
      }),
      singleValue: (base, state) => ({
        ...base,
        fontSize: '0.8em',
        paddingTop: '0.9rem',
        marginLeft: '0',
        boxShadow: 'none',
      }),
      placeholder: base => ({
        ...base,
        fontSize: '0.8em',
        paddingTop: '0.9rem',
        color: '#999',
      }),
      menu: base => ({
        ...base,
        borderRadius: '0',
        margin: '0',
        zIndex: '10',
      }),
      option: (base, state) => ({
        ...base,
        color: '#282828',
        fontSize: '0.8em',
        backgroundColor: state.isSelected ? 'f2f2f2' : '#fff',
        '&:hover': {
          backgroundColor: '#f2f2f2',
        },
      }),
      indicatorSeparator: () => ({
        display: 'none',
      }),
      dropdownIndicator: () => ({
        display: 'none',
      }),
    }

    const option = countries.find(({ value }) => value === this.props.country)

    return (
      <div className={textInputStyles.inputWrapper + ' ' + textInputStyles.noIcon}>
        {/* For debugging you add defaultMenuIsOpen={true} to the component */}
        <label style={{ zIndex: '2' }} className={[textInputStyles.floatingLabel, textInputStyles.transform].join(' ')} htmlFor="countriesSelection">
          <FormattedMessage id="venuePage.reservationInfo.reservationForm.country" />
        </label>
        <Select
          id="countriesSelection"
          className="react-select-container"
          classNamePrefix="react-select"
          options={countries}
          styles={customStyles}
          placeholder={false}
          // TODO: translation for label
          value={{ value: this.props.country, label: option.label }}
          onChange={this.props.handleOnChange}
          //onFocus={this.handleOnFocus}
          //onBlur={this.handleOnBlur}
          isDisabled={false}
          defaultMenuIsOpen={false}
        />
        {this.renderArrowIcon()}
      </div>
    )
  }
}

export default injectIntl(CountryDropdown)
