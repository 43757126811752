import React, { Component } from 'react'
import { connect } from 'react-redux'
import AdditionalListItem from './additionalListItem/additionalListItem'
import RefreshmentsPopup from './popup/refreshmentsPopup/refreshmentsPopup.js'
import AllergiesPopup from './popup/allergiesPopup/allergiesPopup.js'
import AdditionalInformationPopup from './popup/additionalInformationPopup/additionalInformationPopup.js'
import venueListItemStyles from '../venueListItemStyles.module.css'
import venueAdditionalStyles from './venueAdditionals.module.css'
import fonts from '../../../../../../styles/fonts.module.css'
import { formatPrice } from '../../../../../../services/moneyService'
import { localizeObject } from '../../../../../../services/localizationService'
import { injectIntl } from '../../../../../../../plugins/gatsby-plugin-intl-custom'

/*
  edge
  changeUserMessage
  saveAllergies
  allergies
  saveRefreshments
  refreshments
  saveAdditionalInfo
  eventTitle
  eventDescription
  additionalRequirements
*/
class venueAdditionals extends Component {
  constructor(props) {
    super(props)

    this.state = {
      additionalServicePopup: false,
      allergiesPopup: false,
      // equipmentPopup: false,
      additionalInformationPopup: false,
    }

    this.toggleAdditionalServicePopup = this.toggleAdditionalServicePopup.bind(this)
    this.toggleAllergiesPopup = this.toggleAllergiesPopup.bind(this)
    //this.toggleEquipmentPopup = this.toggleEquipmentPopup.bind(this)
    this.toggleAdditionalInformationPopup = this.toggleAdditionalInformationPopup.bind(this)
    this.setRefreshments = this.setRefreshments.bind(this)
  }

  toggleAdditionalServicePopup() {
    const open = this.state.additionalServicePopup
    this.setState({
      additionalServicePopup: !open,
      allergiesPopup: false,
      // equipmentPopup: false,
      additionalInformationPopup: false,
    })
  }
  toggleAllergiesPopup() {
    const open = this.state.allergiesPopup
    this.setState({
      additionalServicePopup: false,
      allergiesPopup: !open,
      // equipmentPopup: false,
      additionalInformationPopup: false,
    })
  }
  // toggleEquipmentPopup() {
  //   const open = this.state.equipmentPopup
  //   this.setState({
  //     additionalServicePopup: false,
  //     allergiesPopup: false,
  //     equipmentPopup: !open,
  //     additionalInformationPopup: false,
  //   })
  // }
  toggleAdditionalInformationPopup() {
    const open = this.state.additionalInformationPopup
    this.setState({
      additionalServicePopup: false,
      allergiesPopup: false,
      // equipmentPopup: false,
      additionalInformationPopup: !open,
    })
  }

  saveAllergies(allergies) {
    this.toggleAllergiesPopup()
    this.props.saveAllergies(allergies)
  }

  saveAdditionalInfo(title, description, other) {
    this.toggleAdditionalInformationPopup()
    this.props.saveAdditionalInfo(title, description, other)
  }

  setRefreshments(venueSelectedRefreshments) {
    this.toggleAdditionalServicePopup()
    this.props.saveRefreshments(venueSelectedRefreshments)
  }

  renderRefreshmentsPopup() {
    if (!this.state.additionalServicePopup) return
    const refreshmentsForUpdate = []
    if (this.props.refreshments) this.props.refreshments.map(r => refreshmentsForUpdate.push(r))
    if (this.props.reduxVenueProducts[0].products.refreshment)
      this.props.reduxVenueProducts[0].products.refreshment.filter(r => !this.containsRefreshment(refreshmentsForUpdate, r)).map(r => refreshmentsForUpdate.push(r))

    return (
      <RefreshmentsPopup
        cancelClick={this.toggleAdditionalServicePopup}
        refreshments={refreshmentsForUpdate}
        venueReservationID={this.props.reduxVenueProducts[0].venueID}
        onSave={this.setRefreshments}
      />
    )
  }

  containsRefreshment(refreshmentsForUpdate, refreshment) {
    return refreshmentsForUpdate.filter(r => r.id === refreshment.id).length > 0
  }

  renderRefreshments() {
    if (!this.props.refreshments || this.props.refreshments.length === 0) return

    return (
      <ul className={venueAdditionalStyles.productList}>
        {this.props.refreshments.map(r => {
          return (
            <li key={r.id} className={venueAdditionalStyles.productListItem}>
              <div>
                {r.count} x {localizeObject(r.name, this.props.intl.locale)}
              </div>
              <div>
                <b>{formatPrice(this.calculateTotalRowPrice(r.price, r.count))} €</b>
              </div>
            </li>
          )
        })}
      </ul>
    )
  }

  calculateTotalRowPrice(price, count) {
    return price * count
  }

  renderAllergiesPopup() {
    if (!this.state.allergiesPopup) return

    return <AllergiesPopup cancelClick={this.toggleAllergiesPopup} saveClick={allergies => this.saveAllergies(allergies)} allergies={this.props.allergies} />
  }

  renderAllergies() {
    if (!this.props.allergies) return

    return (
      <ul className={venueAdditionalStyles.productList}>
        <li className={venueAdditionalStyles.productListItem}>{this.props.allergies}</li>
      </ul>
    )
  }

  renderAdditionalInformationPopup() {
    if (!this.state.additionalInformationPopup) return

    return (
      <AdditionalInformationPopup
        cancelClick={this.toggleAdditionalInformationPopup}
        saveClick={(title, description, other) => this.saveAdditionalInfo(title, description, other)}
        eventTitle={this.props.eventTitle}
        eventDescription={this.props.eventDescription}
        additionalRequirements={this.props.additionalRequirements}
      />
    )
  }

  renderAdditionalInformation() {
    if (!this.props.eventTitle && !this.props.eventDescription && !this.props.additionalRequirements) return

    return (
      <ul className={venueAdditionalStyles.productList}>
        {this.props.eventDescription && (
          <li className={venueAdditionalStyles.productListItem}>
            <span className={fonts.legend}>{this.props.intl.formatMessage({ id: 'reservationPage.typeOfEvent' })}: </span>
            {this.props.eventDescription}
          </li>
        )}
        {this.props.eventTitle && (
          <li className={venueAdditionalStyles.productListItem}>
            <span className={fonts.legend}>{this.props.intl.formatMessage({ id: 'reservationPage.nameInSigns' })}: </span>
            {this.props.eventTitle}
          </li>
        )}
        {this.props.additionalRequirements && (
          <li className={venueAdditionalStyles.productListItem}>
            <span className={fonts.legend}>{this.props.intl.formatMessage({ id: 'reservationPage.otherRequests' })}: </span>
            {this.props.additionalRequirements}
          </li>
        )}
      </ul>
    )
  }

  render() {
    return (
      <div>
        <div className={venueListItemStyles.contentContainer}>
          <ul className={venueAdditionalStyles.additionalList}>
            <li className={venueAdditionalStyles.additionalListItem}>
              <AdditionalListItem
                header={this.props.intl.formatMessage({ id: 'venuePage.additionalSelections.refreshmentTitle' })}
                desc={this.props.intl.formatMessage({ id: 'venuePage.additionalSelections.refreshmentDescription' })}
                buttonText={this.props.intl.formatMessage({
                  id: 'venuePage.additionalSelections.' + (this.props.refreshments && this.props.refreshments.length > 0 ? 'editRefreshments' : 'addRefreshments'),
                })}
                togglePopup={this.toggleAdditionalServicePopup}
              />
              {this.renderRefreshmentsPopup()}
              {this.renderRefreshments()}
            </li>
            <li className={venueAdditionalStyles.additionalListItem}>
              <AdditionalListItem
                header={this.props.intl.formatMessage({ id: 'venuePage.additionalSelections.allergiesTitle' })}
                desc={this.props.intl.formatMessage({ id: 'venuePage.additionalSelections.allergiesDescription' })}
                buttonText={this.props.intl.formatMessage({ id: 'venuePage.additionalSelections.' + (this.props.allergies ? 'editAllergies' : 'addAllergies') })}
                togglePopup={this.toggleAllergiesPopup}
              />
              {this.renderAllergiesPopup()}
              {this.renderAllergies()}
            </li>
            <li className={venueAdditionalStyles.additionalListItem}>
              <AdditionalListItem
                header={this.props.intl.formatMessage({ id: 'venuePage.additionalSelections.additionalInfoTitle' })}
                buttonText={this.props.intl.formatMessage({
                  id: 'venuePage.additionalSelections.' + (this.props.eventTitle || this.props.eventDescription || this.props.additionalRequirements ? 'editAdditionalInfo' : 'addAdditionalInfo'),
                })}
                togglePopup={this.toggleAdditionalInformationPopup}
              />
              {this.renderAdditionalInformationPopup()}
              {this.renderAdditionalInformation()}
            </li>
          </ul>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  data: state.data,
  reduxVenueProducts: state.data.venueProducts,
})

export default connect(mapStateToProps)(injectIntl(venueAdditionals))
