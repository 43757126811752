import React from 'react'
import Navigation from '../../../components/navigation/navigation'
import MainContent from './venueComponents/venueMainContent/venueMainContent'
import SideBar from './venueComponents/venueSideBar/venueSideBar'
import Footer from '../../../components/footer/footer'
import roomStyles from './makeReservation.module.css'
import AvailabilitySingleRoom from '../../queries/availabilitySingleRoomQuery'
import VenueProducts from '../../queries/venueProductsQuery'

/*  Props:
    -edge
*/
export default class MakeReservation extends React.Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props)
    this.anchorLinkClick = this.anchorLinkClick.bind(this)
    this.mainContentRef = React.createRef()
  }

  anchorLinkClick(ref) {
    if (!ref || !ref.current) return

    window.scrollTo({
      top: ref.current.getBoundingClientRect().top + window.scrollY,
      behavior: 'smooth',
    })
  }

  render() {
    const { edge } = this.props
    return (
      <>
        <Navigation />
        <AvailabilitySingleRoom onlineReservable={edge.onlineReservable} roomId={edge.id} />
        <VenueProducts roomId={edge.id} />
        <div className={roomStyles.container}>
          <div ref={this.mainContentRef}>
            <MainContent edge={edge} />
          </div>
          <SideBar edge={edge} anchorLinkClick={() => this.anchorLinkClick(this.mainContentRef)} />
        </div>
        <Footer />
      </>
    )
  }
}
