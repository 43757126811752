import React, { Component } from 'react'
import { connect } from 'react-redux'
import { starttimeValue, endtimeValue } from '../../../../../../../services/searchService'
import availableHoursStyles from './availableHours.module.css'
import fonts from '../../../../../../../styles/fonts.module.css'

// import MockupData from '../../../../../../../../static/calenderMockData.json'

/*  Props
      -disabled
*/
class AvailableHoursDisplay extends Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props)
  }

  getAvailableSlotCount() {
    if (!this.props.reduxVenueAvailability) return null
    if (this.props.reduxVenueAvailability.Error) return null
    if (!this.props.reduxVenueAvailability.available) return null

    const availableSlots = this.props.reduxVenueAvailability.available.map(item => {
      if (item.date !== this.props.reduxSearch.reservationDate) return null
      return item.timeSlots
    })
    return availableSlots.filter(a => a !== null)[0].length
  }

  getHoursAvailability() {
    if (!this.props.reduxVenueAvailability) return null
    if (this.props.reduxVenueAvailability.Error) return null

    const filteredDayAvailability = this.props.reduxVenueAvailability.available.filter(day => day.date === this.props.reduxSearch.reservationDate)
    if (filteredDayAvailability.length !== 1) return null

    let timeSlots = filteredDayAvailability[0].timeSlots.map(t => {
      return {
        ...t,
        formatedStart: this.formatHours(t.start),
      }
    })

    let isSelected = false
    let selectedCount = 0
    if (starttimeValue(this.props.reduxSearch) && endtimeValue(this.props.reduxSearch)) {
      timeSlots = timeSlots.map(t => {
        let isFirstSelected = false

        if (t.start.substring(0, 5) === this.props.reduxSearch.startTime) {
          isSelected = true
          isFirstSelected = true
        }

        if (t.start.substring(0, 5) === this.props.reduxSearch.endTime) {
          isSelected = false
        }

        if (isSelected) {
          selectedCount++
        }

        return {
          ...t,
          selected: isSelected,
          isFirstSelected: isFirstSelected,
        }
      })
    }

    return {
      timeSlots: timeSlots,
      selectedCount: selectedCount,
    }
  }

  formatHours(hour) {
    return hour
      .substring(0, 5)
      .replace(/^0/, '')
      .replace(/^0/, '')
      .replace(/:/, '')
      .replace(/^0/, '')
      .replace(/00$/, '')
  }

  renderEvenSlot(item, index, isLastSelected) {
    return (
      <div
        className={[
          availableHoursStyles.hoursItem,
          item.selected ? availableHoursStyles.selected : '',
          item.isFirstSelected ? availableHoursStyles.firstSelected : '',
          isLastSelected ? availableHoursStyles.lastSelected : '',
        ].join(' ')}
        key={index}
      >
        <div className={[availableHoursStyles.hourMarker, fonts.xSmallParagraphNoMargin].join(' ')}>{item.formatedStart}</div>
        <div className={availableHoursStyles.availability}>
          <div
            className={[
              availableHoursStyles.availabilityIndicator,
              item.status === 'Free' ? availableHoursStyles.available : '',
              item.status === 'Booked' ? availableHoursStyles.booked : '',
              item.status === 'Unknown' || item.status === 'Unavailable' ? availableHoursStyles.unknown : '',
              availableHoursStyles.firstHalf,
            ].join(' ')}
          ></div>
        </div>
      </div>
    )
  }

  renderOddSlot(item, index, isLastSelected) {
    return (
      <div
        className={[
          availableHoursStyles.hoursItem,
          item.selected ? availableHoursStyles.selected : '',
          item.isFirstSelected ? availableHoursStyles.firstSelected : '',
          isLastSelected ? availableHoursStyles.lastSelected : '',
        ].join(' ')}
        style={{ alignSelf: 'flex-end' }}
        key={index}
      >
        <div className={availableHoursStyles.availability}>
          <div
            className={[
              availableHoursStyles.availabilityIndicator,
              item.status === 'Free' ? availableHoursStyles.available : '',
              item.status === 'Booked' ? availableHoursStyles.booked : '',
              item.status === 'Unknown' || item.status === 'Unavailable' ? availableHoursStyles.unknown : '',
              availableHoursStyles.secondHalf,
            ].join(' ')}
          ></div>
        </div>
      </div>
    )
  }

  render() {
    const availability = this.getHoursAvailability()
    let markedSelectedCount = 0

    return (
      <div className={availableHoursStyles.hoursWrapper}>
        {availability &&
          availability.timeSlots &&
          availability.timeSlots.map((item, index) => {
            if (item.selected) markedSelectedCount++

            if (index % 2) {
              return this.renderOddSlot(item, index, availability.selectedCount === markedSelectedCount)
            } else {
              return this.renderEvenSlot(item, index, availability.selectedCount === markedSelectedCount)
            }
          })}
        {this.props.disabled && <div className={availableHoursStyles.disabled}></div>}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    reduxSearch: state.search,
    reduxVenueAvailability: state.data.venueAvailability,
  }
}

export default connect(mapStateToProps)(AvailableHoursDisplay)
