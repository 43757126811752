export const localizeTableConfigurationContent = (TABLECONFIGURATIONTYPE, language) => {
  if (!TABLECONFIGURATIONTYPE) return null
  let tableConfigurationType = TABLECONFIGURATIONTYPE.replace('TABLECONFIGURATIONTYPE_', '').toLowerCase()
  let tableConfigurationContent
  const localLanguage = language

  const content = {
    fi: {
      tableConfigurations: {
        anytablemodel: 'Mikä tahansa pöytämuoto',
        diplomat: 'Diplomaatti',
        class_shape: 'Luokka',
        groupwork: 'Ryhmätyöpöytä',
        u_shape: 'U-pöytä',
        chairrow: 'Tuolirivi',
        cocktail: 'Cocktail',
        round: 'round',
        eat_buffet: 'Buffet',
        eat_tableservice: 'Pöytiintarjoilu',
      },
    },
    en: {
      tableConfigurations: {
        anytablemodel: 'Any table model',
        diplomat: 'Diplomat',
        class_shape: 'Class-shape',
        groupwork: 'Group Work',
        u_shape: 'U-table',
        chairrow: 'Seat row',
        cocktail: 'Cocktail',
        round: 'round',
        eat_buffet: 'Buffet',
        eat_tableservice: 'Serving tables',
      },
    },
    sv: {
      tableConfigurations: {
        anytablemodel: 'Oavsett bordsmodell',
        diplomat: 'Diplomat',
        class_shape: 'Klassrum',
        groupwork: 'Gruppbord',
        u_shape: 'U-bord',
        chairrow: 'Stolsrad',
        cocktail: 'Cocktail',
        round: 'round',
        eat_buffet: 'Buffet',
        eat_tableservice: 'Serveringsbord',
      },
    },
    et: {
      tableConfigurations: {
        anytablemodel: 'Mis tahes laua mudel',
        diplomat: 'Diplomaat',
        class_shape: 'Klassiruum',
        groupwork: 'Rühmatööd tabel',
        u_shape: 'U-tabel',
        chairrow: 'Toolide rida',
        cocktail: 'Kokteil',
        round: 'round',
        eat_buffet: 'Einelaud',
        eat_tableservice: 'Serveerimislauad',
      },
    },
  }

  switch (localLanguage) {
    case 'fi':
      tableConfigurationContent = content.fi
      break
    case 'en':
      tableConfigurationContent = content.en
      break
    case 'sv':
      tableConfigurationContent = content.sv
      break
    case 'et':
      tableConfigurationContent = content.et
      break

    default:
      break
  }

  return tableConfigurationContent.tableConfigurations[tableConfigurationType]
}
