import React, { Component } from 'react'
import TextInput from '../../../common/textInput/textInput'
import textInputStyles from '../../../common/textInput/textInput.module.css'
import inputIconStyles from '../../../common/inputIconStyles.module.css'
import companyIdStyles from './companyIdSelector.module.css'
import XIcon from '../../../../../static/svgs/x.svg'
import Button from '../../../common/defaultButton/defaultButton'
import { connect } from 'react-redux'
import { injectIntl, FormattedMessage } from '../../../../../plugins/gatsby-plugin-intl-custom'
import { changePriceLevelCode, FETCHING_PRODUCTS_STATUS_FETCHING, FETCHING_PRODUCTS_STATUS_ERROR } from '../../../../state/reduxApp'

/*
 */
class CompanyIdSelector extends Component {
  constructor(props) {
    super(props)

    this.state = {
      companyId: '',
    }

    this.handleInputClear = this.handleInputClear.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
  }

  handleInputChange(event) {
    event.preventDefault()
    this.setState({ companyId: event.target.value })
  }

  handleInputClear(event) {
    event.preventDefault()
    this.setState({ companyId: '' })
    this.props.dispatchPriceLevelCode(undefined)
  }

  renderClearIcon() {
    return this.state.companyId ? <XIcon className={inputIconStyles.clearIcon} onClick={this.handleInputClear} /> : ''
  }

  render() {
    const clearIcon = this.renderClearIcon()

    return (
      <div className={companyIdStyles.wrapper}>
        <div className={[textInputStyles.inputWrapper, textInputStyles.noIcon].join(' ')}>
          <TextInput
            id="companyIdInput"
            type="text"
            active={this.state.companyId ? 1 : 0}
            required
            label={this.props.intl.formatMessage({ id: 'search.companyId' })}
            value={this.state.companyId}
            onChange={this.handleInputChange}
          />
          {clearIcon}
          {this.props.reduxVenueProducts && this.props.reduxFetchingVenueProductsStatus === FETCHING_PRODUCTS_STATUS_ERROR && this.state.companyId && (
            <span className={textInputStyles.reminder}>
              <FormattedMessage id="search.checkCode" />
            </span>
          )}
        </div>
        <div className={companyIdStyles.buttonWrapper}>
          <Button
            type="default"
            onClick={() => this.props.dispatchPriceLevelCode(this.state.companyId)}
            buttonText={this.props.intl.formatMessage({ id: 'search.update' })}
            isLoadingButton={this.props.reduxFetchingVenueProductsStatus === FETCHING_PRODUCTS_STATUS_FETCHING}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  reduxFetchingVenueProductsStatus: state.queryStatuses.fetchingVenueProductsStatus,
  reduxVenueProducts: state.data.venueProducts,
})
const mapDispatchToProps = dispatch => ({
  dispatchPriceLevelCode: callbackLink => dispatch(changePriceLevelCode(callbackLink)),
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CompanyIdSelector))
