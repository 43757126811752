import React, { Component } from 'react'
import { formatPrice } from '../../../../../../services/moneyService'
import fonts from '../../../../../../styles/fonts.module.css'
import sideBarStyles from '../venueSideBar.module.css'
import { injectIntl } from '../../../../../../../plugins/gatsby-plugin-intl-custom'

class venuePackages extends Component {
  // eslint-disable-next-line
  constructor() {
    super()
  }

  renderPrice() {
    const { normalPriceCheapestPackage } = this.props
    if (!normalPriceCheapestPackage && normalPriceCheapestPackage.price <= 0) return null
    return (
      <>
        <p className={fonts.redPrice}>
          {this.props.intl.formatMessage({ id: 'venuePage.startingFrom' })} {formatPrice(normalPriceCheapestPackage.price)} €{' '}
          {!normalPriceCheapestPackage.isMinimalPricePackage && ' / ' + this.props.intl.formatMessage({ id: 'venuePage.person' })}{' '}
        </p>
      </>
    )
  }

  render() {
    return (
      <div>
        <div className={sideBarStyles.tableConfigurations}>{this.renderPrice()}</div>
      </div>
    )
  }
}

export default injectIntl(venuePackages)
