import React, { Component } from 'react'
import { localizeObject } from '../../../../../services/localizationService'
import HotelInfo from './hotelInfo/hotelInfo'
import VenueTableIcons from './venueTableIcons/venueTableIcons'
import VenuePackages from './venuePackages/venuePackages'
import VenueAttributes from './venueAttributes/venueAttributes'
import VenueTechAndEquipments from './venueTechAndEquipments/venueTechAndEquipments'
import { connect } from 'react-redux'
import ImageCarousel from '../venueMainContent/imageCarousel/imageCarousel.js'
import fonts from '../../../../../styles/fonts.module.css'
import sideBarStyles from './venueSideBar.module.css'
import accordionStyles from '../../../../common/accordion/accordion.module.css'
import VenueDescription from '../venueMainContent/venueDescription/venueDescription'
import AccordionItem from '../../../../common/accordion/accordionItem/accordionItem'
import ArrowLeft from '../../../../../../static/svgs/arrowleft.svg'
import { Link } from 'gatsby'
import { injectIntl, FormattedMessage } from '../../../../../../plugins/gatsby-plugin-intl-custom'

class VenueSideBar extends Component {
  constructor(props) {
    super(props)

    this.state = {
      desc: localizeObject(this.props.edge.longDescription, this.props.intl.locale).trim(),
      fadeIn: true,
      venueTableIconsVisible: false,
      venuePackagesVisible: false,
      venueAttributesVisible: false,
      venueTechAndEquipmentsVisible: false,
    }

    // Used in info accordion
    this.infoTableformRef = React.createRef()
    this.infoPackagesRef = React.createRef()
    this.infoAttributesRef = React.createRef()
    this.infoEquipmentsRef = React.createRef()

    this.toggleFadeIn = this.toggleFadeIn.bind(this)
    this.toggleVenueTableIconsVisibility = this.toggleVenueTableIconsVisibility.bind(this)
    this.toggleVenuePackagesVisibility = this.toggleVenuePackagesVisibility.bind(this)
    this.toggleVenueAttributesVisibility = this.toggleVenueAttributesVisibility.bind(this)
    this.toggleVenueTechAndEquipmentsVisibility = this.toggleVenueTechAndEquipmentsVisibility.bind(this)
    this.scrollToReservationSection = this.scrollToReservationSection.bind(this)
  }

  renderVenueImages(images) {
    const amountOfImages = images.length
    if (amountOfImages < 1) return null

    // if there are more than 1 images, render carousel
    if (amountOfImages > 1) return <ImageCarousel images={this.props.edge.images} amountOfSlides={amountOfImages} />
    // if there is just a single image, do not render carousel
    return <img className={sideBarStyles.image} alt={localizeObject(images[0].alternativeText, this.props.intl.locale)} src={images[0].url} />
    // TODO: if there are no images at all, should there be a placeholder image?
  }

  toggleFadeIn() {
    const fadeIn = this.state.fadeIn
    this.setState({ fadeIn: !fadeIn })
  }

  toggleVenueTableIconsVisibility() {
    const visibility = this.state.venueTableIconsVisible
    const ref = this.infoTableformRef
    this.setState(
      {
        venueTableIconsVisible: !visibility,
        venuePackagesVisible: false,
        venueAttributesVisible: false,
        venueTechAndEquipmentsVisible: false,
      },
      () => {
        if (!visibility) this.scrollToReservationSection(ref)
      }
    )
  }
  toggleVenuePackagesVisibility() {
    const visibility = this.state.venuePackagesVisible
    const ref = this.infoPackagesRef
    this.setState(
      {
        venueTableIconsVisible: false,
        venuePackagesVisible: !visibility,
        venueAttributesVisible: false,
        venueTechAndEquipmentsVisible: false,
      },
      () => {
        if (!visibility) this.scrollToReservationSection(ref)
      }
    )
  }
  toggleVenueAttributesVisibility() {
    const visibility = this.state.venueAttributesVisible
    const ref = this.infoAttributesRef
    this.setState(
      {
        venueTableIconsVisible: false,
        venuePackagesVisible: false,
        venueAttributesVisible: !visibility,
        venueTechAndEquipmentsVisible: false,
      },
      () => {
        if (!visibility) this.scrollToReservationSection(ref)
      }
    )
  }

  toggleVenueTechAndEquipmentsVisibility() {
    const visibility = this.state.venueTechAndEquipmentsVisible
    const ref = this.infoEquipmentsRef
    this.setState(
      {
        venueTableIconsVisible: false,
        venuePackagesVisible: false,
        venueAttributesVisible: false,
        venueTechAndEquipmentsVisible: !visibility,
      },
      () => {
        if (!visibility) this.scrollToReservationSection(ref)
      }
    )
  }

  scrollToReservationSection(ref) {
    if (ref) window.scrollTo(0, ref.current.getBoundingClientRect().top + window.scrollY)
  }

  renderURL() {
    let pathUrl
    if (this.props.reduxLanguage === 'fi') {
      pathUrl = '/'
    }
    if (this.props.reduxLanguage === 'sv') {
      pathUrl = '/sv'
    }
    if (this.props.reduxLanguage === 'en') {
      pathUrl = '/en'
    }
    if (this.props.reduxLanguage === 'et') {
      pathUrl = '/et'
    }
    return pathUrl
  }

  renderVenueTableIcons(edge) {
    if (edge.tableConfigurations && edge.tableConfigurations.length) {
      return (
        <div className={sideBarStyles.sideBarSection}>
          <h3 className={fonts.heading3}>
            <FormattedMessage id="venuePage.tableModelHeader" />
          </h3>
          <VenueTableIcons edge={edge} header={this.props.intl.formatMessage({ id: 'venuePage.tableModelHeader' })} />
        </div>
      )
    }
  }

  renderVenueCheapestPackage(edge) {
    const cheapestMeetingPackage = edge.normalPriceCheapestPackage.filter(p => p.type === 'Meeting')

    if (edge.onlineReservable && cheapestMeetingPackage.length === 1) {
      return (
        <div className={sideBarStyles.sideBarSection}>
          <h3 className={fonts.heading3}>
            <FormattedMessage id="venuePage.venuePackages.header" />
          </h3>
          <VenuePackages
            normalPriceCheapestPackage={cheapestMeetingPackage[0]}
            header={cheapestMeetingPackage[0].isMinimalPricePackage ? 'venuePage.packageInfo.venueRent' : 'venuePage.venuePackages.header'}
          />
        </div>
      )
    }
  }

  renderResponsiveView(edge) {
    /* Visible only on smaller screens, like mobile devices. */
    const cheapestMeetingPackage = edge.normalPriceCheapestPackage.filter(p => p.type === 'Meeting')

    return (
      <div className={sideBarStyles.contentContainerLower}>
        <h2 className={fonts.heading2}>{this.props.intl.formatMessage({ id: 'venuePage.informationOnVenueHeading' })}</h2>
        <VenueDescription className={sideBarStyles.venueDescriptionLarge} desc={this.state.desc} fadeIn={this.state.fadeIn} toggleFadeIn={this.toggleFadeIn} />
        {/* TODO: handle accordion clicks */}
        <ul className={accordionStyles.accordion}>
          {edge.tableConfigurations && edge.tableConfigurations.length > 0 && (
            <AccordionItem
              header={this.props.intl.formatMessage({ id: 'venuePage.tableModelHeader' })}
              index="1"
              onClick={this.toggleVenueTableIconsVisibility}
              active={this.state.venueTableIconsVisible}
              ref={this.infoTableformRef}
            >
              <VenueTableIcons edge={edge} header={this.props.intl.formatMessage({ id: 'venuePage.tableModelHeader' })} />
            </AccordionItem>
          )}
          {this.props.edge.onlineReservable && cheapestMeetingPackage.length === 1 ? (
            <AccordionItem
              header={
                edge.normalPriceCheapestPackage.isMinimalPricePackage
                  ? this.props.intl.formatMessage({ id: 'venuePage.packageInfo.venueRent' })
                  : this.props.intl.formatMessage({ id: 'venuePage.venuePackages.header' })
              }
              index="2"
              onClick={this.toggleVenuePackagesVisibility}
              active={this.state.venuePackagesVisible}
              ref={this.infoPackagesRef}
            >
              <VenuePackages normalPriceCheapestPackage={cheapestMeetingPackage[0]} />
            </AccordionItem>
          ) : (
            ''
          )}

          <AccordionItem
            header={this.props.intl.formatMessage({ id: 'venuePage.venueQualities' })}
            index="3"
            onClick={this.toggleVenueAttributesVisibility}
            active={this.state.venueAttributesVisible}
            ref={this.infoAttributesRef}
          >
            <VenueAttributes edge={edge} />
          </AccordionItem>

          <AccordionItem
            header={this.props.intl.formatMessage({ id: 'venuePage.techAndEquipments.header' })}
            index="4"
            onClick={this.toggleVenueTechAndEquipmentsVisibility}
            active={this.state.venueTechAndEquipmentsVisible}
            ref={this.infoEquipmentsRef}
          >
            <VenueTechAndEquipments />
          </AccordionItem>
        </ul>
      </div>
    )
  }

  render() {
    const { edge } = this.props
    const language = this.props.intl.locale
    const venueImages = this.renderVenueImages(edge.images, language)

    return (
      <div className={sideBarStyles.sideBar}>
        <div className={sideBarStyles.backLinkContainer}>
          <Link to={this.renderURL()} className={fonts.textLink} state={this.props.reduxState}>
            <ArrowLeft className={sideBarStyles.arrowLeft} />
            <span>{this.props.intl.formatMessage({ id: 'search.linkToSearchPage' })}</span>
          </Link>
        </div>
        <div className={sideBarStyles.imageContainer}>{venueImages}</div>
        <div id="sidebar" className={sideBarStyles.contentContainerUpper}>
          <HotelInfo edge={edge} anchorLinkClick={this.props.anchorLinkClick} />

          <div className={sideBarStyles.venueDetails}>
            {this.renderVenueTableIcons(edge)}
            {this.renderVenueCheapestPackage(edge)}

            <div className={sideBarStyles.sideBarSection}>
              <h3 className={fonts.heading3}>{this.props.intl.formatMessage({ id: 'venuePage.venueQualities' })}</h3>
              <VenueAttributes edge={edge} />
            </div>

            <div className={sideBarStyles.sideBarSection}>
              <h3 className={fonts.heading3}>{this.props.intl.formatMessage({ id: 'venuePage.techAndEquipments.header' })}</h3>
              <VenueTechAndEquipments />
            </div>
          </div>
        </div>

        {this.renderResponsiveView(edge)}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  reduxState: state,
  reduxLanguage: state.language,
  meetingType: state.search.meetingType,
})

export default connect(mapStateToProps)(injectIntl(VenueSideBar))
