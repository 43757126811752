import React, { Component } from 'react'
import fonts from '../../../../../../../../styles/fonts.module.css'
import popupStyles from '../../popup/popup.module.css'
import additionalInformationPopupStyles from './additionalInformationPopupStyles.module.css'
import TextArea from '../../../../../../../common/textArea/textArea.js'
import TextInput from '../../../../../../../common/textInput/textInput.js'
import TextInputStyles from '../../../../../../../common/textInput/textInput.module.css'
import DefaultButton from '../../../../../../../common/defaultButton/defaultButton.js'
import { injectIntl } from '../../../../../../../../../plugins/gatsby-plugin-intl-custom'

/* Props 
cancelClick
saveClick
*/

class AdditionalInformationPopup extends Component {
  constructor(props) {
    super(props)

    this.state = {
      eventDescription: this.props.eventDescription,
      eventTitle: this.props.eventTitle,
      additionalRequirements: this.props.additionalRequirements,
      eventDescriptionCharacters: this.props.eventDescription.length,
      additionalRequirementsCharacters: this.props.additionalRequirements.length,
    }

    this.handleOnChange = this.handleOnChange.bind(this)
  }

  componentDidMount() {
    document.body.classList.add('popupOpen')
  }

  componentWillUnmount() {
    document.body.classList.remove('popupOpen')
  }

  handleOnChange(event) {
    this.setState({ [event.target.name]: event.target.value, [event.target.name + 'Characters']: event.target.value.length })
  }

  render() {
    const { eventTitle, eventDescription, additionalRequirements } = this.state

    return (
      <div>
        <div className={popupStyles.mask}></div>
        <div className={popupStyles.popUpWindow}>
          <div className={popupStyles.header}>
            <h3 className={fonts.heading3noMargin}>{this.props.intl.formatMessage({ id: 'reservationPage.otherInformation' })}</h3>
          </div>
          <div className={popupStyles.body}>
            <div className={additionalInformationPopupStyles.fieldsetSmall}>
              <h4 className={fonts.legend}>{this.props.intl.formatMessage({ id: 'reservationPage.typeOfEvent' })}</h4>
              <p className={fonts.smallParagraph}>{this.props.intl.formatMessage({ id: 'reservationPage.typeOfEventDescription' })}</p>
              <TextArea
                id="eventDescriptionID"
                name="eventDescription"
                value={this.state.eventDescription}
                labelText={this.props.intl.formatMessage({ id: 'reservationPage.typeOfEvent' })}
                maxCharacters="50"
                usedCharacters={this.state.eventDescriptionCharacters}
                active={this.state.eventDescription !== '' ? 1 : 0}
                onChange={this.handleOnChange}
              />
            </div>
            <div className={additionalInformationPopupStyles.fieldset}>
              <h4 className={fonts.legend}>{this.props.intl.formatMessage({ id: 'reservationPage.nameInSigns' })}</h4>
              <div className={[TextInputStyles.inputWrapper, TextInputStyles.noIcon, additionalInformationPopupStyles.inputWrapper].join(' ')}>
                <TextInput
                  id="eventTitleID"
                  type="text"
                  name="eventTitle"
                  value={this.state.eventTitle}
                  maxLength="50"
                  label={this.props.intl.formatMessage({ id: 'reservationPage.nameInSigns' })}
                  active={this.state.eventTitle !== '' ? 1 : 0}
                  onChange={this.handleOnChange}
                />
              </div>
            </div>
            <div className={additionalInformationPopupStyles.fieldset}>
              <h4 className={fonts.legend}>{this.props.intl.formatMessage({ id: 'reservationPage.otherRequests' })}</h4>
              <p className={fonts.smallParagraph}>{this.props.intl.formatMessage({ id: 'reservationPage.otherRequestsDescription' })}</p>
              <TextArea
                id="additionalRequirementsID"
                name="additionalRequirements"
                value={this.state.additionalRequirements}
                labelText={this.props.intl.formatMessage({ id: 'reservationPage.otherRequests' })}
                maxCharacters="500"
                usedCharacters={this.state.additionalRequirementsCharacters}
                active={this.state.additionalRequirements !== '' ? 1 : 0}
                onChange={this.handleOnChange}
              />
            </div>
          </div>
          <div className={popupStyles.footer}>
            <div className={popupStyles.footerLeftSection}>
              <DefaultButton type="secondary" buttonText={this.props.intl.formatMessage({ id: 'buttons.cancel' })} onClick={this.props.cancelClick} />
            </div>
            <div className={popupStyles.footerRightSection}>
              <DefaultButton
                type="primary"
                buttonText={this.props.intl.formatMessage({ id: 'buttons.save' })}
                onClick={() => this.props.saveClick(eventTitle, eventDescription, additionalRequirements)}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default injectIntl(AdditionalInformationPopup)
