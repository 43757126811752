import React, { Component } from 'react'

import ClassTableIcon from '../../../../../../../static/svgs/class.svg'
import FoodserviceIcon from '../../../../../../../static/svgs/foodservice.svg'
import UtableIcon from '../../../../../../../static/svgs/utable.svg'
import ChairRowIcon from '../../../../../../../static/svgs/chairrow.svg'
import GroupIcon from '../../../../../../../static/svgs/group.svg'
import DiplomateIcon from '../../../../../../../static/svgs/diplomate.svg'
import CocktailIcon from '../../../../../../../static/svgs/cocktail.svg'
import RoundIcon from '../../../../../../../static/svgs/round.svg'
import BuffetIcon from '../../../../../../../static/svgs/buffet.svg'
import { injectIntl } from '../../../../../../../plugins/gatsby-plugin-intl-custom'
import venueTableIconsStyles from './venueTableIcons.module.css'

class venueTableIcons extends Component {
  getTableConfiguration(tableConfigurationName) {
    if (!this.props.edge) return null
    if (!this.props.edge.tableConfigurations) return null
    if (this.props.edge.tableConfigurations.length < 1) return null

    return this.props.edge.tableConfigurations.find(item => {
      // Preprod-api returned null tableConfiguration values 26.11.2019, that's why !!item
      return !!item && item.tableConfigurationName === tableConfigurationName
    })
  }

  renderMaxCount(tableConfiguration) {
    if (!tableConfiguration || !tableConfiguration.valueRange) return null
    return <p className={venueTableIconsStyles.tableMaxCount}>{tableConfiguration.valueRange.max}</p>
  }

  renderIcon(tableConfiguration, icon, tableConfTitle) {
    if (tableConfiguration) {
      return (
        <div className={venueTableIconsStyles.tableConfigurationsItem}>
          <div className={venueTableIconsStyles.tableModelIcons}>{icon}</div>
          <span className={venueTableIconsStyles.tableName}>{tableConfTitle}</span>
          {this.renderMaxCount(tableConfiguration)}
        </div>
      )
    }

    // if no tableConfiguration found return null
    return null
  }

  renderDiplomat() {
    let tableConfiguration = this.getTableConfiguration('Diplomat')
    const tableConfTitle = this.props.intl.formatMessage({ id: 'search.tableConfigurations.diplomat' })
    const icon = <DiplomateIcon alt="Diplomate icon" />
    return this.renderIcon(tableConfiguration, icon, tableConfTitle)
  }

  renderClass_Shape() {
    let tableConfiguration = this.getTableConfiguration('Class_Shape')
    const tableConfTitle = this.props.intl.formatMessage({ id: 'search.tableConfigurations.class_shape' })
    const icon = <ClassTableIcon alt="Class shape Icon" />
    return this.renderIcon(tableConfiguration, icon, tableConfTitle)
  }

  renderGroupWork() {
    let tableConfiguration = this.getTableConfiguration('GroupWork')
    const tableConfTitle = this.props.intl.formatMessage({ id: 'search.tableConfigurations.groupwork' })
    const icon = <GroupIcon alt="Group icon" />
    return this.renderIcon(tableConfiguration, icon, tableConfTitle)
  }

  renderU_Shape() {
    let tableConfiguration = this.getTableConfiguration('U_Shape')
    const tableConfTitle = this.props.intl.formatMessage({ id: 'search.tableConfigurations.u_shape' })
    const icon = <UtableIcon alt="Utable icon" />
    return this.renderIcon(tableConfiguration, icon, tableConfTitle)
  }

  renderChairRow() {
    let tableConfiguration = this.getTableConfiguration('ChairRow')
    const tableConfTitle = this.props.intl.formatMessage({ id: 'search.tableConfigurations.chairrow' })
    const icon = <ChairRowIcon alt="Chairrow icon" />
    return this.renderIcon(tableConfiguration, icon, tableConfTitle)
  }

  renderCocktail() {
    let tableConfiguration = this.getTableConfiguration('Cocktail')
    const tableConfTitle = this.props.intl.formatMessage({ id: 'search.tableConfigurations.cocktail' })
    const icon = <CocktailIcon alt="Cocktail icon" />
    return this.renderIcon(tableConfiguration, icon, tableConfTitle)
  }

  renderRound() {
    let tableConfiguration = this.getTableConfiguration('Round')
    const tableConfTitle = this.props.intl.formatMessage({ id: 'search.tableConfigurations.round' })
    const icon = <RoundIcon alt="Round icon" />
    return this.renderIcon(tableConfiguration, icon, tableConfTitle)
  }

  renderEat_Buffet() {
    let tableConfiguration = this.getTableConfiguration('Eat_Buffet')
    const tableConfTitle = this.props.intl.formatMessage({ id: 'search.tableConfigurations.eat_buffet' })
    const icon = <BuffetIcon alt="Buffet icon" />
    return this.renderIcon(tableConfiguration, icon, tableConfTitle)
  }

  renderEat_Tableservice() {
    let tableConfiguration = this.getTableConfiguration('Eat_Tableservice')
    const tableConfTitle = this.props.intl.formatMessage({ id: 'search.tableConfigurations.eat_tableservice' })
    const icon = <FoodserviceIcon alt="Foodservice icon" />
    return this.renderIcon(tableConfiguration, icon, tableConfTitle)
  }

  render() {
    return (
      <div className={venueTableIconsStyles.wrapper}>
        {/*<p className={venueTableIconsStyles.sectionHeader}>{this.props.header}</p>*/}
        {this.renderDiplomat()}
        {this.renderClass_Shape()}
        {this.renderGroupWork()}
        {this.renderU_Shape()}
        {this.renderChairRow()}
        {this.renderCocktail()}
        {this.renderRound()}
        {this.renderEat_Buffet()}
        {this.renderEat_Tableservice()}
      </div>
    )
  }
}

export default injectIntl(venueTableIcons)
