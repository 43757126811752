import React from 'react'
import { localizeObject } from '../../../../../../services/localizationService'
import fonts from '../../../../../../styles/fonts.module.css'
import sideBarStyles from '../venueSideBar.module.css'
import { injectIntl, FormattedMessage } from '../../../../../../../plugins/gatsby-plugin-intl-custom'
import Button from '../../../../../common/defaultButton/defaultButton'

const hotelInfo = ({ edge, anchorLinkClick, intl }) => {
  const language = intl.locale
  let contactInformationLink

  switch (language) {
    case 'sv':
      contactInformationLink = process.env.GATSBY_SH_URL + '/sv/kontaktuppgifter-och-samtalsavgifter'
      break
    case 'en':
      contactInformationLink = process.env.GATSBY_SH_URL + '/en/contacts-and-call-rates'
      break
    case 'et':
      // Heidi Haapio 23.11.2023: Most part of chain content is going to be in FI, EN, SV.
      contactInformationLink = process.env.GATSBY_SH_URL + '/en/contacts-and-call-rates'
      break
    default:
      contactInformationLink = process.env.GATSBY_SH_URL + '/yhteystiedot-ja-puheluiden-hinnat'
      break
  }

  return (
    <div className={sideBarStyles.hotelInfo}>
      <h1 className={fonts.sideBarHeading1}>{localizeObject(edge.name, language)}</h1>
      <p className={fonts.basicParagraphNoMargin}>
        {intl.formatMessage({ id: 'venuePage.hotelInfo.squares' })} <strong>{edge.size}</strong> m&#xb2;, {intl.formatMessage({ id: 'venuePage.hotelInfo.max' })} <strong>{edge.maxCapacity}</strong>{' '}
        {intl.formatMessage({ id: 'venuePage.hotelInfo.perPerson' })}
      </p>
      <div className={sideBarStyles.hotelInfo}>
        <a className={sideBarStyles.hotelName} href={localizeObject(edge.establishment.homepage, language)} target="_blank" rel="noopener noreferrer">
          {localizeObject(edge.establishment.name, language)}
          <i className={sideBarStyles.rightArrowIcon + ' material-icons'}>keyboard_arrow_right</i>
        </a>
        <span className={sideBarStyles.hotelAddress}>
          {localizeObject(edge.address.streetAddress, language)}, {edge.address.zipCode} {localizeObject(edge.address.municipality, language)}
        </span>
        {edge.phoneNumber && (
          <>
            <span className={sideBarStyles.hotelPhoneNumber}>
              <FormattedMessage id="venuePage.hotelInfo.phoneAbbreviation" /> {edge.phoneNumber}
            </span>
            <a className={fonts.textLink} href={contactInformationLink} target="_blank" rel="noopener noreferrer">
              <FormattedMessage id="venuePage.hotelInfo.phonePrices" />
            </a>
          </>
        )}
      </div>
      <div className={sideBarStyles.bookingLink}>
        <Button type="primary" onClick={anchorLinkClick} buttonText={`${intl.formatMessage({ id: 'venuePage.bookVenueHeading' })} ${localizeObject(edge.name, language)}`} />
      </div>
    </div>
  )
}

export default injectIntl(hotelInfo)
