import React from 'react'
import accordionItemStyles from './accordionItem.module.css'
import fonts from '../../../../styles/fonts.module.css'
import Checkmark from '../../../../../static/svgs/check.svg'
import ArrowDown from '../../../../../static/svgs/arrowdown.svg'

/*
  Props
    expanded
    disabled
    onClick
    index
    header
    stateFilled
    children
*/
const AccordionItem = React.forwardRef((props, ref) => {
  return (
    <li
      /* eslint-disable-next-line */
      className={[accordionItemStyles.accordionItem, props.expanded ? accordionItemStyles.expanded : '', props.disabled ? accordionItemStyles.disabled : ''].join(' ')}
      active={props.expanded ? 1 : 0}
      ref={ref}
    >
      {/* eslint-disable-next-line */}
      <div className={accordionItemStyles.header} onClick={props.onClick} index={props.index}>
        <div className={accordionItemStyles.headerText}>
          <h3 className={fonts.heading4noMargin}>{props.header}</h3>
        </div>
        {props.stateFilled && (
          <div className={accordionItemStyles.userInputFilledWrapper}>
            <Checkmark className={accordionItemStyles.checkmark} />
            <span className={[accordionItemStyles.userInputFilled, fonts.smallParagraphNoMargin].join(' ')}> {props.stateFilled}</span>
          </div>
        )}
        <div className={accordionItemStyles.arrow}>
          <ArrowDown />
        </div>
      </div>

      <div className={[accordionItemStyles.content, props.expanded ? accordionItemStyles.openContent : ''].join(' ')}>{props.children}</div>
    </li>
  )
})

export default AccordionItem
