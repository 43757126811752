import React, { Component } from 'react'
import { formatPrice } from '../../../../../../../services/moneyService'
import { localizeObject } from '../../../../../../../services/localizationService'
import fonts from '../../../../../../../styles/fonts.module.css'
import venuePackageButtonStyles from './venuePackageButton.module.css'
import SEtukorttiLogo from '../../../../../../../../static/svgs/s-etukortti_logo.svg'
import { injectIntl } from '../../../../../../../../plugins/gatsby-plugin-intl-custom'

/*
    key
    name
    value={localizeObject(venuePackage.name, this.props.intl.locale)}
    description={localizeObject(venuePackage.description, this.props.intl.locale)}
    price
    coopMemberPrice
    showPrices
    onChange
    venuePackage
    changePackageInformation
    shortVenuePackageMeetingTypeBy
*/
class VenuePackageButton extends Component {
  render() {
    const { venuePackage } = this.props
    if (!venuePackage.types.includes(this.props.shortVenuePackageMeetingTypeBy)) return null

    return (
      <div className={[venuePackageButtonStyles.wrapper, this.props.checked && venuePackageButtonStyles.selected].join(' ')}>
        <label className={venuePackageButtonStyles.container}>
          <input
            type="radio"
            checked={this.props.checked}
            name={venuePackage.id}
            value={venuePackage.id}
            onClick={() => this.props.changePackageInformation(venuePackage.id, localizeObject(venuePackage.name, this.props.intl.locale))}
            onChange={this.props.onChange}
            // disabled={props.disabled}
          />
          <span className={venuePackageButtonStyles.checkmark} />
          <div className={venuePackageButtonStyles.headingContainer}>
            <p className={fonts.packageHeading}>{this.props.value}</p>
          </div>

          <div className={venuePackageButtonStyles.pricesContainer}>
            <div className={venuePackageButtonStyles.price}>
              <p className={fonts.normalPrice}>
                {this.props.showPrices ? (
                  <>
                    {`${formatPrice(venuePackage.price)} €`} {venuePackage && venuePackage.isMinimalPricePackage ? '' : ' / hlö'}
                  </>
                ) : (
                  <>---</>
                )}
              </p>
            </div>
            {venuePackage.coopMemberPrice !== null && !this.props.isBusinessCustomer && (
              <div className={venuePackageButtonStyles.price}>
                <p className={fonts.coopMemberPrice}>
                  {this.props.showPrices ? (
                    <>
                      {`${formatPrice(venuePackage.coopMemberPrice)} €`} {venuePackage && venuePackage.isMinimalPricePackage ? '' : ' / hlö'}
                    </>
                  ) : (
                    <>---</>
                  )}
                </p>
                <SEtukorttiLogo alt="Asiakasomistajahinta" className={venuePackageButtonStyles.coopMemberLogo} />
              </div>
            )}
          </div>
          <div className={venuePackageButtonStyles.description}>
            <div
              id="venueDescription"
              className={fonts.smallParagraphNoMargin}
              //TODO: Resolve how to get rid of possible undesired htmlString tags.
              // A.k.a. How to prevent user for being able to feed <h1> tag into the content
              dangerouslySetInnerHTML={{ __html: this.props.description }}
            />
            {/* {venuePackage.coopMemberPrice ? <span className={venuePackageButtonStyles.contract}>sopimushinta</span> : ''} */}
          </div>
        </label>
      </div>
    )
  }
}

export default injectIntl(VenuePackageButton)
