import React from 'react'
import DefaultButton from '../../../../common/defaultButton/defaultButton'
import { injectIntl } from '../../../../../../plugins/gatsby-plugin-intl-custom'

/*  Props:
    -edgeId
 */
const venueOfferRequestForm = ({ edgeId, intl }) => {
  // Cant assign dynamically, as process.env is only available during build time
  const reservationForm = {
    fi: process.env.GATSBY_SH_RESERVATION_FORM_URL_FI,
    sv: process.env.GATSBY_SH_RESERVATION_FORM_URL_SV,
    en: process.env.GATSBY_SH_RESERVATION_FORM_URL_EN,
    et: process.env.GATSBY_SH_RESERVATION_FORM_URL_ET,
  }
  const reservationFormUrl = reservationForm[intl.locale]

  return <DefaultButton type="primary" buttonText={intl.formatMessage({ id: 'venuePage.reservationForm' })} onClick={() => (window.location.href = reservationFormUrl)} />
}

export default injectIntl(venueOfferRequestForm)
