import React from 'react'
import ArrowDown from '../../../../../../../static/svgs/arrowdown.svg'
import venueDescriptionStyles from './venueDescription.module.css'
import { useIntl } from '../../../../../../../plugins/gatsby-plugin-intl-custom'

const VenueDescription = ({ desc, fadeIn, toggleFadeIn }) => {
  const intl = useIntl()

  return (
    <div>
      {desc.length < 600 ? (
        <div>
          <p
            id="venueDescription"
            className={venueDescriptionStyles.venueDescription}
            //TODO: Resolve how to get rid of possible undesired htmlString tags.
            // A.k.a. How to prevent user for being able to feed <h1> tag into the content
            dangerouslySetInnerHTML={{ __html: desc }}
          />
        </div>
      ) : (
        <div>
          <div>
            <p
              id="venueDescription"
              className={[desc.length ? venueDescriptionStyles.venueDescriptionFade : '', fadeIn ? '' : venueDescriptionStyles.venueDescriptionShown, venueDescriptionStyles.venueDescription].join(
                ' '
              )}
              //TODO: Resolve how to get rid of possible undesired htmlString tags.
              // A.k.a. How to prevent user for being able to feed <h1> tag into the content
              dangerouslySetInnerHTML={{ __html: desc }}
            />
            <div className={fadeIn ? venueDescriptionStyles.venueDescriptionFadeBlock : ''}></div>
          </div>
          <div>
            {/* TODO: Fix this linter warning */}
            {/* eslint-disable-next-line */}
            <p className={venueDescriptionStyles.toggleDescription} onClick={toggleFadeIn}>
              {intl.formatMessage({ id: 'venuePage.showFullDescription' })}{' '}
              <ArrowDown className={[venueDescriptionStyles.arrowIcon, fadeIn ? '' : venueDescriptionStyles.arrowIconRotated].join(' ')} />
            </p>
          </div>
        </div>
      )}
    </div>
  )
}

export default VenueDescription
