import React, { Component } from 'react'
import { connect } from 'react-redux'
import venueListItemStyles from '../venueListItemStyles.module.css'
import TableSelectButton from '../../../../../common/tableSelectButton/tableSelectButton'
import PreReservationbutton from '../../../../buttons/preReservationbutton'
import ClassTableIcon from '../../../../../../../static/svgs/class.svg'
import FoodserviceIcon from '../../../../../../../static/svgs/foodservice.svg'
import UtableIcon from '../../../../../../../static/svgs/utable.svg'
import ChairRowIcon from '../../../../../../../static/svgs/chairrow.svg'
import GroupIcon from '../../../../../../../static/svgs/group.svg'
import DiplomateIcon from '../../../../../../../static/svgs/diplomate.svg'
import CocktailIcon from '../../../../../../../static/svgs/cocktail.svg'
import RoundIcon from '../../../../../../../static/svgs/round.svg'
import BuffetIcon from '../../../../../../../static/svgs/buffet.svg'
import fonts from '../../../../../../styles/fonts.module.css'
import { injectIntl } from '../../../../../../../plugins/gatsby-plugin-intl-custom'

import {
  // MEETINGTYPE_SAUNA,
  changeTableconfigurationType,
  TABLECONFIGURATIONTYPE_ANYTABLEMODEL,
  TABLECONFIGURATIONTYPE_DIPLOMAT,
  TABLECONFIGURATIONTYPE_CLASS_SHAPE,
  TABLECONFIGURATIONTYPE_GROUPWORK,
  TABLECONFIGURATIONTYPE_U_SHAPE,
  TABLECONFIGURATIONTYPE_CHAIRROW,
  TABLECONFIGURATIONTYPE_COCKTAIL,
  TABLECONFIGURATIONTYPE_ROUND,
  TABLECONFIGURATIONTYPE_EAT_BUFFET,
  TABLECONFIGURATIONTYPE_EAT_TABLESERVICE,
} from '../../../../../../state/reduxApp'

/*  Props
      -edge
      -showNextSection
      -changeUserMessage
      -handleTableformNotAvailableForPersonCount
 */
class VenueTable extends Component {
  constructor(props) {
    super(props)

    this.handleTableConfigurationChange = this.handleTableConfigurationChange.bind(this)
  }

  getTableConfiguration(tableConfigurationName) {
    if (!this.props.edge) return null
    if (!this.props.edge.tableConfigurations) return null
    if (this.props.edge.tableConfigurations.length < 1) return null

    return this.props.edge.tableConfigurations.find(item => {
      // Preprod-api returned null tableConfiguration values 26.11.2019, that's why !!item
      return !!item && item.tableConfigurationName === tableConfigurationName
    })
  }

  validateIfTableSelectButtonDisabled(tableValue, TABLECONFIGURATIONTYPE) {
    if (TABLECONFIGURATIONTYPE !== TABLECONFIGURATIONTYPE_ANYTABLEMODEL && this.props.reduxPersonCount > tableValue) {
      return true
    }
    if (TABLECONFIGURATIONTYPE === TABLECONFIGURATIONTYPE_ANYTABLEMODEL && this.props.reduxPersonCount > this.props.edge.maxCapacity) {
      return true
    }
    return false
  }

  isTableformSelected(TABLECONFIGURATIONTYPE, tableValue) {
    if (TABLECONFIGURATIONTYPE !== TABLECONFIGURATIONTYPE_ANYTABLEMODEL && this.props.reduxPersonCount > tableValue) {
      return false
    }

    if (this.props.reduxSearch.tableConfigurationType === TABLECONFIGURATIONTYPE) {
      return true
    }

    return false
  }

  renderButton(icon, tableConfiguration, tableConfTitle, TABLECONFIGURATIONTYPE) {
    if (tableConfiguration) {
      const tableValue = tableConfiguration.valueRange.max
      return (
        <div>
          <div className={venueListItemStyles.buttonItem}>
            <TableSelectButton
              icon={icon}
              name="venueTableSelectButton"
              value={`${tableConfTitle} ${tableValue}`}
              checked={this.isTableformSelected(TABLECONFIGURATIONTYPE, tableValue)}
              onChange={() => this.handleTableConfigurationChange(TABLECONFIGURATIONTYPE, tableValue)}
              disabled={(tableValue && this.props.reduxPersonCount > tableValue) || this.props.reduxPersonCount > this.props.edge.maxCapacity}
              buttonDisabled={this.validateIfTableSelectButtonDisabled(tableValue, TABLECONFIGURATIONTYPE)}
            />
          </div>
        </div>
      )
    }

    // if no tableConfiguration found return null
    return null
  }

  renderAnyModel() {
    const tableConfTitle = this.props.intl.formatMessage({ id: 'search.tableConfigurations.anytablemodel' })
    return this.renderButton('', { tableConfigurationName: 'anytablemodel', valueRange: { max: '' } }, tableConfTitle, TABLECONFIGURATIONTYPE_ANYTABLEMODEL)
  }

  renderDiplomat() {
    let tableConfiguration = this.getTableConfiguration('Diplomat')
    if (!tableConfiguration) return
    const tableConfTitle = this.props.intl.formatMessage({ id: 'search.tableConfigurations.diplomat' })
    return this.renderButton(<DiplomateIcon />, tableConfiguration, tableConfTitle, TABLECONFIGURATIONTYPE_DIPLOMAT)
  }

  renderClass_Shape() {
    let tableConfiguration = this.getTableConfiguration('Class_Shape')
    if (!tableConfiguration) return
    const tableConfTitle = this.props.intl.formatMessage({ id: 'search.tableConfigurations.class_shape' })
    return this.renderButton(<ClassTableIcon />, tableConfiguration, tableConfTitle, TABLECONFIGURATIONTYPE_CLASS_SHAPE)
  }

  renderGroupWork() {
    let tableConfiguration = this.getTableConfiguration('GroupWork')
    if (!tableConfiguration) return
    const tableConfTitle = this.props.intl.formatMessage({ id: 'search.tableConfigurations.groupwork' })
    return this.renderButton(<GroupIcon />, tableConfiguration, tableConfTitle, TABLECONFIGURATIONTYPE_GROUPWORK)
  }

  renderU_Shape() {
    let tableConfiguration = this.getTableConfiguration('U_Shape')
    if (!tableConfiguration) return
    const tableConfTitle = this.props.intl.formatMessage({ id: 'search.tableConfigurations.u_shape' })
    return this.renderButton(<UtableIcon />, tableConfiguration, tableConfTitle, TABLECONFIGURATIONTYPE_U_SHAPE)
  }

  renderChairRow() {
    let tableConfiguration = this.getTableConfiguration('ChairRow')
    if (!tableConfiguration) return
    const tableConfTitle = this.props.intl.formatMessage({ id: 'search.tableConfigurations.chairrow' })
    return this.renderButton(<ChairRowIcon />, tableConfiguration, tableConfTitle, TABLECONFIGURATIONTYPE_CHAIRROW)
  }

  renderCocktail() {
    let tableConfiguration = this.getTableConfiguration('Cocktail')
    if (!tableConfiguration) return
    const tableConfTitle = this.props.intl.formatMessage({ id: 'search.tableConfigurations.cocktail' })
    return this.renderButton(<CocktailIcon />, tableConfiguration, tableConfTitle, TABLECONFIGURATIONTYPE_COCKTAIL)
  }

  renderRound() {
    let tableConfiguration = this.getTableConfiguration('Round')
    if (!tableConfiguration) return
    const tableConfTitle = this.props.intl.formatMessage({ id: 'search.tableConfigurations.cocktail' })
    return this.renderButton(<RoundIcon />, tableConfiguration, tableConfTitle, TABLECONFIGURATIONTYPE_ROUND)
  }

  renderEat_Buffet() {
    let tableConfiguration = this.getTableConfiguration('Eat_Buffet')
    if (!tableConfiguration) return
    const tableConfTitle = this.props.intl.formatMessage({ id: 'search.tableConfigurations.eat_buffet' })
    return this.renderButton(<BuffetIcon />, tableConfiguration, tableConfTitle, TABLECONFIGURATIONTYPE_EAT_BUFFET)
  }

  renderEat_Tableservice() {
    let tableConfiguration = this.getTableConfiguration('Eat_Tableservice')
    if (!tableConfiguration) return
    const tableConfTitle = this.props.intl.formatMessage({ id: 'search.tableConfigurations.eat_tableservice' })
    return this.renderButton(<FoodserviceIcon />, tableConfiguration, tableConfTitle, TABLECONFIGURATIONTYPE_EAT_TABLESERVICE)
  }

  handleTableConfigurationChange(value) {
    this.props.dispatchTableconfigurationType(value)
  }

  componentDidMount() {
    this.props.handleTableformNotAvailableForPersonCount()
  }

  render() {
    return (
      <div>
        <div className={venueListItemStyles.contentContainer}>
          <p className={[fonts.legend, fonts.bold].join(' ')}>{this.props.intl.formatMessage({ id: 'search.tablemodel' })} *</p>
          <div className={venueListItemStyles.buttonWrapper}>
            {/* 'Any model' should be available if there are no specified tableforms at all OR if there are more than one */}
            {this.props.edge.tableConfigurations.length !== 1 && this.renderAnyModel()}
            {this.renderDiplomat()}
            {this.renderClass_Shape()}
            {this.renderGroupWork()}
            {this.renderU_Shape()}
            {this.renderChairRow()}
            {this.renderCocktail()}
            {this.renderRound()}
            {this.renderEat_Buffet()}
            {this.renderEat_Tableservice()}
          </div>
          <PreReservationbutton
            edge={this.props.edge}
            afterPreReservationClick={index => this.props.showNextSection(index)}
            changeUserMessage={message => this.props.changeUserMessage(message)}
            buttonText={this.props.intl.formatMessage({ id: 'venuePage.nextPhase' })}
            disabled={!this.props.reduxTableform}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    reduxSearch: state.search,
    reduxPersonCount: state.search.personCount,
    reduxTableform: state.search.tableConfigurationType,
  }
}

const mapDispatchToProps = dispatch => ({
  dispatchTableconfigurationType: callbackLink => dispatch(changeTableconfigurationType(callbackLink)),
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(VenueTable))
