import React, { Component } from 'react'
import { connect } from 'react-redux'
import { LOCALIZEDVENUEATTRIBUTES } from '../../../../../../constants/LOCALIZEDVENUEATTRIBUTES'
import { MEETINGTYPE_MEETING, MEETINGTYPE_SAUNA, MEETINGTYPE_PARTY } from '../../../../../../state/reduxApp'
import CheckIcon from '../../../../../../../static/svgs/check.svg'
import sideBarStyles from '../venueSideBar.module.css'
import { injectIntl } from '../../../../../../../plugins/gatsby-plugin-intl-custom'

class venueAttributes extends Component {
  render() {
    let venueType
    let venueAttributes

    if (this.props.meetingType === MEETINGTYPE_MEETING) {
      venueType = 'meetings'
    }
    if (this.props.meetingType === MEETINGTYPE_SAUNA) {
      venueType = 'sauna'
    }
    if (this.props.meetingType === MEETINGTYPE_PARTY) {
      venueType = 'celebration'
    }

    venueAttributes = LOCALIZEDVENUEATTRIBUTES[this.props.intl.locale][venueType]

    return (
      <div className={sideBarStyles.tableConfigurations}>
        <ul className={sideBarStyles.checkList}>
          {this.props.edge.attributes.map(
            (item, index) =>
              venueAttributes[item] && (
                <li key={index} className={sideBarStyles.checkListItem}>
                  <CheckIcon className={sideBarStyles.checkListIcon} />
                  <span>{venueAttributes[item]}</span>
                </li>
              )
          )}
        </ul>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    meetingType: state.search.meetingType,
  }
}

export default connect(mapStateToProps)(injectIntl(venueAttributes))
