import React, { Component } from 'react'
import { differenceInSecondsToCurrentTime } from '../../../services/timeService'
import { withApollo } from 'react-apollo'
import {
  timerDefaultTime,
  changeCancelReservation,
  changeCreateReservation,
  changeConfirmReservation,
  changeReservation,
  setMutatingReservationTrue,
  setMutatingReservationFalse,
  changeReservationLastname,
  changeReservationId,
} from '../../../state/reduxApp'
import gql from 'graphql-tag'
import { connect } from 'react-redux'
import styles from './timers.module.css'
import fonts from '../../../styles/fonts.module.css'
import ClockIcon from '../../../../static/svgs/clock.svg'
import Arrow from '../../../../static/svgs/arrowdown.svg'
import Button from '../../common/defaultButton/defaultButton'

/*
  Props
    afterTimeOut
    totalPrice
    totalText
    preReservationValidText
    minText
    buttonText
    toggleCancelPopup
*/
class PreReservationTimer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      timeOut: false,
      timeLeft: timerDefaultTime,
      mobileTimerVisible: false,
    }
    this.startTimer = this.startTimer.bind(this)
    this.stopTimer = this.stopTimer.bind(this)
    this.toggleMobileTimerVisibility = this.toggleMobileTimerVisibility.bind(this)
  }

  cancelPreReservation() {
    const reservationID = this.props.reduxPreReservation.id

    if (this.props.reduxWaitingForMutationResponse) return
    if (!reservationID) return

    this.props.dispatchMutatingReservationTrue()

    const mutation = {
      mutation: CANCEL_PRE_RESERVATION,
      variables: { reservationID },
    }

    this.props.client
      .mutate(mutation)
      .then(result => {
        let cancelReservation = {}
        if (result.data && result.data.cancelPreReservation) cancelReservation = result.data.cancelPreReservation
        this.props.dispatchCancelReservation(cancelReservation)
        this.props.dispatchCreateReservation(undefined)
        this.props.dispatchConfirmReservation(undefined)
        this.props.dispatchReservation(undefined)
        this.props.dispatchChangeReservationLastname('')
        this.props.dispatchChangeReservationId('')
        this.props.dispatchMutatingReservationFalse()
        this.props.afterTimeOut()
      })
      .catch(() => {
        this.props.dispatchCancelReservation({})
        this.props.dispatchCreateReservation(undefined)
        this.props.dispatchConfirmReservation(undefined)
        this.props.dispatchReservation(undefined)
        this.props.dispatchChangeReservationLastname('')
        this.props.dispatchChangeReservationId('')
        this.props.dispatchMutatingReservationFalse()
        this.props.afterTimeOut()
      })
  }

  handleInterval() {
    if (!this.props.reduxPreReservation) {
      this.stopTimer()
      this.setState({ timeOut: true })
      return
    }

    if (!this.props.reduxPreReservation.creationTime) {
      this.stopTimer()
      this.setState({ timeOut: true })
      return
    }

    const timeLeft = timerDefaultTime - differenceInSecondsToCurrentTime(this.props.reduxPreReservation.creationTime)

    //  Time ended handled
    if (timeLeft < 1) {
      this.stopTimer()
      this.setState({
        timeOut: true,
      })

      this.props.dispatchCreateReservation(undefined)
      this.cancelPreReservation()

      return
    }

    this.setState({ timeLeft: timeLeft })
  }

  toggleMobileTimerVisibility() {
    const visible = this.state.mobileTimerVisible

    this.setState({
      mobileTimerVisible: !visible,
    })
  }

  startTimer() {
    this.setState({
      timeOut: false,
    })
    this.timer = setInterval(() => this.handleInterval(), 1000)
  }

  stopTimer() {
    clearInterval(this.timer)
  }

  componentDidMount() {
    this.startTimer()
  }
  componentWillUnmount() {
    this.stopTimer()
  }

  timeLeftInMinutesAndSeconds() {
    if (!this.props.reduxPreReservation || !this.props.reduxPreReservation.creationTime) return
    if (!this.state.timeLeft) return
    const minutes = Math.floor(this.state.timeLeft / 60)
    const seconds = this.state.timeLeft - minutes * 60
    return (
      <span className={fonts.timerText}>
        {minutes >= 10 ? minutes : '0' + minutes}:{seconds >= 10 ? seconds : '0' + seconds}
      </span>
    )
  }

  renderPrice() {
    if (!this.props.totalPrice) return null
    return (
      <div className={styles.priceWrapper}>
        {this.props.totalText} {this.props.totalPrice} €
      </div>
    )
  }

  timerAnimation() {
    return (
      <div className={[styles.timer, this.state.mobileTimerVisible ? styles.mobileTimerVisible : ''].join(' ')}>
        <div className={styles.iconWrapper}>
          <ClockIcon className={styles.icon} />
        </div>
        <div className={styles.textWrapper}>
          <span className={styles.timeLeftText}>{this.props.preReservationValidText}&nbsp;</span>
          {this.timeLeftInMinutesAndSeconds()}&nbsp;{this.props.minText}
        </div>
        <button className={styles.arrowIconWrapper} onClick={() => this.toggleMobileTimerVisibility()}>
          <Arrow className={styles.arrowIcon} />
        </button>
        {this.renderPrice()}
        <div className={styles.buttonWrapper}>
          <Button id="timeButtonId" type="smallDefaultLight" buttonText={this.props.buttonText} onClick={() => this.props.toggleCancelPopup()} />
        </div>
      </div>
    )
  }

  render() {
    if (this.state.timeOut) return null

    return (
      <div>
        <div>{this.timerAnimation()}</div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    reduxPreReservation: state.data.createReservation,
    reduxWaitingForMutationResponse: state.queryStatuses.mutatingReservation,
  }
}

const mapDispatchToProps = dispatch => ({
  dispatchCancelReservation: callbackLink => dispatch(changeCancelReservation(callbackLink)),
  dispatchCreateReservation: callbackLink => dispatch(changeCreateReservation(callbackLink)),
  dispatchConfirmReservation: callbackLink => dispatch(changeConfirmReservation(callbackLink)),
  dispatchReservation: callbackLink => dispatch(changeReservation(callbackLink)),
  dispatchChangeReservationId: callbackLink => dispatch(changeReservationId(callbackLink)),
  dispatchChangeReservationLastname: callbackLink => dispatch(changeReservationLastname(callbackLink)),
  dispatchMutatingReservationTrue: callbackLink => dispatch(setMutatingReservationTrue(callbackLink)),
  dispatchMutatingReservationFalse: callbackLink => dispatch(setMutatingReservationFalse(callbackLink)),
})

export default withApollo(connect(mapStateToProps, mapDispatchToProps)(PreReservationTimer))

const CANCEL_PRE_RESERVATION = gql`
  mutation cancelPreReservation($reservationID: ID!) {
    cancelPreReservation(reservationID: $reservationID) {
      id
    }
  }
`
