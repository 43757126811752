import React, { Component } from 'react'
import maskStyles from './loadingMask.module.css'
import LoadingIcon from '../../../../static/svgs/loading_animation.svg'

class LoadingMask extends Component {
  componentWillMount() {
    document.body.classList.add('popupOpen')
  }
  componentWillUnmount() {
    document.body.classList.remove('popupOpen')
  }
  render() {
    return (
      <div className={maskStyles.loadingMask}>
        <LoadingIcon className={maskStyles.loadingIcon} />
      </div>
    )
  }
}

export default LoadingMask
