import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  changeUserFirstname,
  changeUserLastname,
  changeUserPhone,
  changeUserEmail,
  changeUserCompanyName,
  changeUserVAT,
  changeUserStreetAddress,
  changeUserStreetCode,
  changeUserCity,
  changeUserCountry,
  changeContactPersonFirstname,
  changeContactPersonLastname,
  changeContactPersonPhone,
  changeContactPersonEmail,
  changePaymentType,
  changeHasContactPerson,
} from '../../../../../../state/reduxApp'
import {
  isValidName,
  isValidStreetAddress,
  isValidZIP,
  isValidCity,
  isValidPhoneNumber,
  isValidEmail,
  isValidCompanyName,
  isValidBusinessId,
  getFormattedPhoneNumber,
} from '../../../../../../services/hessuValidationService'
import venueListItemStyles from '../venueListItemStyles.module.css'
import userInformationStyles from './venueReservationUserInformation.module.css'
import PreReservationbutton from '../../../../buttons/preReservationbutton'
import fonts from '../../../../../../styles/fonts.module.css'
import TextInput from '../../../../../common/textInput/textInput'
import textInputStyles from '../../../../../common/textInput/textInput.module.css'
import RadioButton from '../../../../../common/radioButton/radioButton'
import CheckBox from '../../../../../common/checkBox/checkBox'
import CountryDropdown from './countryDropdown/countryDropdown'
import SCardLogo from '../../../../../../../static/svgs/s-card_logo.svg'
import SEtukorttiLogo from '../../../../../../../static/svgs/s-etukortti_logo.svg'
import { injectIntl, FormattedMessage } from '../../../../../../../plugins/gatsby-plugin-intl-custom'
import { differenceInDaysToCurrentDate } from '../../../../../../services/timeService'

class VenueReservationUserInformation extends Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props)

    /* #region bindings */

    this.handleChangeUserFirstname = this.handleChangeUserFirstname.bind(this)
    this.handleChangeUserLastname = this.handleChangeUserLastname.bind(this)
    this.handleChangeUserPhone = this.handleChangeUserPhone.bind(this)
    this.handlechangeUserEmail = this.handlechangeUserEmail.bind(this)
    this.handleChangeUserCompanyName = this.handleChangeUserCompanyName.bind(this)
    this.handleChangeUserVAT = this.handleChangeUserVAT.bind(this)
    this.handleChangeUserStreetAddress = this.handleChangeUserStreetAddress.bind(this)
    this.handleChangeUserStreetCode = this.handleChangeUserStreetCode.bind(this)
    this.handleChangeUserCity = this.handleChangeUserCity.bind(this)
    this.handleChangeUserCountry = this.handleChangeUserCountry.bind(this)

    this.handleChangeContactPersonFirstname = this.handleChangeContactPersonFirstname.bind(this)
    this.handleChangeContactPersonLastname = this.handleChangeContactPersonLastname.bind(this)
    this.handleChangeContactPersonPhone = this.handleChangeContactPersonPhone.bind(this)
    this.handleChangeContactPersonEmail = this.handleChangeContactPersonEmail.bind(this)

    this.handleChangePaymentType = this.handleChangePaymentType.bind(this)

    this.handleChangeHasContactPerson = this.handleChangeHasContactPerson.bind(this)

    this.validateName = this.validateName.bind(this)
    this.validatePhone = this.validatePhone.bind(this)
    this.validateEmail = this.validateEmail.bind(this)
    this.validateStreetAddress = this.validateStreetAddress.bind(this)
    this.validateCity = this.validateCity.bind(this)
    this.validateZIP = this.validateZIP.bind(this)
    this.validateContactPersonPhone = this.validateContactPersonPhone.bind(this)
    this.validateCompanyId = this.validateCompanyId.bind(this)
    this.validateCompanyName = this.validateCompanyName.bind(this)

    /* #endregion */

    this.state = {
      userFirstname: { showError: false },
      userLastname: { showError: false },
      userPhone: { showError: false },
      userEmail: { showError: false },
      userStreetAddress: { showError: false },
      userZIP: { showError: false },
      userCity: { showError: false },
      userCompanyName: { showError: false },
      userCompanyId: { showError: false },
      contactPersonFirstname: { showError: false },
      contactPersonLastname: { showError: false },
      contactPersonPhone: { showError: false },
      contactPersonEmail: { showError: false },
    }
  }

  componentDidMount() {
    const user = this.props.reduxUser
    const contactPerson = this.props.reduxContactPerson

    // if some user data is already in Redux, validate that immediately
    if (user.firstname && !isValidName(user.firstname)) this.setState({ userFirstname: { showError: true } })
    if (user.lastname && !isValidName(user.lastname)) this.setState({ userLastname: { showError: true } })
    if (user.phone && !isValidPhoneNumber(user.phone)) this.setState({ userPhone: { showError: true } })
    if (user.email && !isValidEmail(user.email)) this.setState({ userEmail: { showError: true } })
    if (user.streetAddress && !isValidStreetAddress(user.streetAddress)) this.setState({ userStreetAddress: { showError: true } })
    if (user.zipCode && !isValidZIP(user.zipCode)) this.setState({ userZIP: { showError: true } })
    if (user.city && !isValidCity(user.city)) this.setState({ userCity: { showError: true } })
    if (user.companyName && !isValidCompanyName(user.companyName)) this.setState({ userCompanyName: { showError: true } })
    if (user.VATnumber && !isValidBusinessId(user.VATnumber)) this.setState({ userCompanyId: { showError: true } })
    if (user.VATnumber && !isValidCompanyName(user.companyName)) this.setState({ userCompanyName: { showError: true } })

    if (contactPerson.firstname && !isValidName(contactPerson.firstname)) this.setState({ contactPersonFirstname: { showError: true } })
    if (contactPerson.lastname && !isValidName(contactPerson.lastname)) this.setState({ contacPersonLastname: { showError: true } })
    if (contactPerson.phone && !isValidPhoneNumber(contactPerson.phone)) this.setState({ contactPersonPhone: { showError: true } })
    if (contactPerson.email && !isValidEmail(contactPerson.email)) this.setState({ contactPersonEmail: { showError: true } })
  }

  handleChangeHasContactPerson() {
    const checked = this.props.reduxContactPerson.hasContactPerson

    if (!checked) {
      this.props.dispatchHasContactPerson(true)
      return
    }
    this.props.dispatchHasContactPerson(false)
    this.props.dispatchContactPersonFirstname('')
    this.props.dispatchContactPersonLastname('')
    this.props.dispatchContactPersonPhone('')
    this.props.dispatchContactPersonEmail('')
    this.setState({
      contactPersonFirstname: { showError: false },
      contactPersonLastname: { showError: false },
      contactPersonPhone: { showError: false },
      contactPersonEmail: { showError: false },
    })
  }

  nextButtonDisabled() {
    const user = this.props.reduxUser
    const contactPerson = this.props.reduxContactPerson

    if (!isValidName(user.firstname)) return true
    if (!isValidName(user.lastname)) return true
    if (!isValidPhoneNumber(user.phone)) return true
    if (!isValidEmail(user.email)) return true
    if (!isValidStreetAddress(user.streetAddress)) return true
    if (!isValidZIP(user.zipCode)) return true
    if (!isValidCity(user.city)) return true
    if (user.companyName && !isValidCompanyName(user.companyName)) return true
    if (user.VATnumber && !isValidBusinessId(user.VATnumber)) return true
    if (user.VATnumber && !isValidCompanyName(user.companyName)) return true
    if (!this.props.reduxPaymentType) return true

    if (!contactPerson.hasContactPerson) return false
    if (!isValidName(contactPerson.firstname)) return true
    if (!isValidName(contactPerson.lastname)) return true
    if (!isValidPhoneNumber(contactPerson.phone)) return true
    if (!isValidEmail(contactPerson.email)) return true
  }

  prePaymentDisabled(date) {
    if (!date) return false

    let diff = differenceInDaysToCurrentDate(date)
    if (diff < 14) return true

    return false
  }

  /* #region dispatch */
  handleChangeUserFirstname(event) {
    // if there is a visible error, validate already when user is typing so that it's obvious when input becomes valid again
    if (this.state.userFirstname.showError) this.validateName(event)
    this.props.dispatchFirstname(event.target.value)
  }
  handleChangeUserLastname(event) {
    if (this.state.userLastname.showError) this.validateName(event)
    this.props.dispatchLastname(event.target.value)
  }
  handleChangeUserPhone(event) {
    if (this.state.userPhone.showError) this.validatePhone(event)
    this.props.dispatchPhone(event.target.value)
  }
  handlechangeUserEmail(event) {
    if (this.state.userEmail.showError) this.validateEmail(event)
    this.props.dispatchEmail(event.target.value)
  }
  handleChangeUserCompanyName(event) {
    if (this.state.userCompanyName.showError) this.validateCompanyName(event)
    this.props.dispatchCompanyName(event.target.value)
  }
  handleChangeUserVAT(event) {
    if (this.state.userCompanyId.showError) this.validateCompanyId(event)
    this.props.dispatchVAT(event.target.value)
  }
  handleChangeUserStreetAddress(event) {
    if (this.state.userStreetAddress.showError) this.validateStreetAddress(event)
    this.props.dispatchStreetAddress(event.target.value)
  }
  handleChangeUserStreetCode(event) {
    if (this.state.userZIP.showError) this.validateZIP(event)
    this.props.dispatchStreetCode(event.target.value)
  }
  handleChangeUserCity(event) {
    if (this.state.userCity.showError) this.validateCity(event)
    this.props.dispatchCity(event.target.value)
  }
  handleChangeUserCountry(event) {
    this.props.dispatchUserCountry(event.value)
  }
  handleChangeContactPersonFirstname(event) {
    if (this.state.contactPersonFirstname.showError) this.validateName(event)
    this.props.dispatchContactPersonFirstname(event.target.value)
  }
  handleChangeContactPersonLastname(event) {
    if (this.state.contactPersonLastname.showError) this.validateName(event)
    this.props.dispatchContactPersonLastname(event.target.value)
  }
  handleChangeContactPersonPhone(event) {
    if (this.state.contactPersonPhone.showError) this.validateContactPersonPhone(event)
    this.props.dispatchContactPersonPhone(event.target.value)
  }
  handleChangeContactPersonEmail(event) {
    if (this.state.contactPersonEmail.showError) this.validateEmail(event)
    this.props.dispatchContactPersonEmail(event.target.value)
  }
  handleChangePaymentType(event) {
    this.props.dispatchPaymentType(event.target.value)
  }

  /* #endregion */

  /* #region validation */

  validateCompanyId(event) {
    const id = event.target.value

    if (!id && !this.props.reduxUser.companyName) {
      this.setState({ userCompanyId: { showError: false } })
      this.setState({ userCompanyName: { showError: false } })
      return
    }

    if (!id) {
      this.setState({ userCompanyId: { showError: false } })
      return
    }

    if (isValidBusinessId(id)) {
      this.setState({ userCompanyId: { showError: false } })
    } else {
      this.setState({ userCompanyId: { showError: true } })
    }

    if (!isValidCompanyName(this.props.reduxUser.companyName)) {
      this.setState({ userCompanyName: { showError: true } })
      return
    }

    this.setState({ userCompanyName: { showError: false } })
  }

  validateCompanyName(event) {
    const name = event.target.value

    if (name && !isValidCompanyName(name)) {
      this.setState({ userCompanyName: { showError: true } })
      return
    }

    if (!name && this.props.reduxUser.VATnumber) {
      this.setState({ userCompanyName: { showError: true } })
      return
    }

    this.setState({ userCompanyName: { showError: false } })
  }

  validateName(event) {
    const name = event.target.name

    if (isValidName(event.target.value)) {
      this.setState({ [name]: { showError: false } })
      return
    }
    this.setState({ [name]: { showError: true } })
  }

  validatePhone(event) {
    const number = getFormattedPhoneNumber(event.target.value)

    if (number) {
      this.props.dispatchPhone(number)
      this.setState({ userPhone: { showError: false } })
      return
    }
    this.setState({ userPhone: { showError: true } })
  }

  validateContactPersonPhone(event) {
    const number = getFormattedPhoneNumber(event.target.value)

    if (number) {
      this.props.dispatchContactPersonPhone(number)
      this.setState({ contactPersonPhone: { showError: false } })
      return
    }
    this.setState({ contactPersonPhone: { showError: true } })
  }

  validateEmail(event) {
    const name = event.target.name

    if (isValidEmail(event.target.value)) {
      this.setState({ [name]: { showError: false } })
      return
    }
    this.setState({ [name]: { showError: true } })
  }

  validateZIP(event) {
    const name = event.target.name

    if (isValidZIP(event.target.value)) {
      this.setState({ [name]: { showError: false } })
      return
    }
    this.setState({ [name]: { showError: true } })
  }

  validateStreetAddress(event) {
    const name = event.target.name

    if (isValidStreetAddress(event.target.value)) {
      this.setState({ [name]: { showError: false } })
      return
    }
    this.setState({ [name]: { showError: true } })
  }

  validateCity(event) {
    const name = event.target.name

    if (isValidCity(event.target.value)) {
      this.setState({ [name]: { showError: false } })
      return
    }
    this.setState({ [name]: { showError: true } })
  }

  /* #endregion */

  renderCustomerRegisterUrl = () => {
    switch (this.props.intl.locale) {
      case 'fi':
        return `${process.env.GATSBY_SH_URL}/tietosuoja/asiakasrekisteri`
      case 'sv':
        return `${process.env.GATSBY_SH_URL}/sv/registerdeklaration/kundregister`
      case 'en':
        return `${process.env.GATSBY_SH_URL}/en/privacy-policy/customer-register`
      case 'et':
        return `${process.env.GATSBY_SH_URL}/et/andmekaitsejuhendid/kliendiregister`
      default:
        return `${process.env.GATSBY_SH_URL}/en/privacy-policy/customer-register`
    }
  }

  renderContactPersonInformation() {
    return this.props.reduxContactPerson.hasContactPerson ? (
      <>
        <p className={fonts.legend}>{this.props.intl.formatMessage({ id: 'venuePage.reservationInfo.reservationForm.contactPersonDetails' })}</p>
        <div className={userInformationStyles.fieldsetFourth}>
          <div className={[textInputStyles.inputWrapper, textInputStyles.noIcon].join(' ')}>
            <TextInput
              id="contactpersonfirstnameId"
              name="contactPersonFirstname"
              type="text"
              active={this.props.reduxContactPerson.firstname.length > 0 ? 1 : 0}
              label={this.props.intl.formatMessage({ id: 'venuePage.reservationInfo.reservationForm.firstName' }) + ` *`}
              value={this.props.reduxContactPerson.firstname}
              onChange={this.handleChangeContactPersonFirstname}
              onBlur={this.validateName}
              required={true}
            />
            {this.state.contactPersonFirstname.showError ? (
              <span className={textInputStyles.reminder}>{this.props.intl.formatMessage({ id: 'venuePage.reservationInfo.reservationForm.firstNameIsMandatory' })}</span>
            ) : (
              ''
            )}
          </div>
          <div className={textInputStyles.inputWrapper + ' ' + textInputStyles.noIcon}>
            <TextInput
              id="contactpersonlastnameId"
              name="contactPersonLastname"
              type="text"
              active={this.props.reduxContactPerson.lastname.length > 0 ? 1 : 0}
              label={this.props.intl.formatMessage({ id: 'venuePage.reservationInfo.reservationForm.lastName' }) + ` *`}
              value={this.props.reduxContactPerson.lastname}
              onChange={this.handleChangeContactPersonLastname}
              onBlur={this.validateName}
              required={true}
            />
            {this.state.contactPersonLastname.showError ? (
              <span className={textInputStyles.reminder}>{this.props.intl.formatMessage({ id: 'venuePage.reservationInfo.reservationForm.lastNameIsMandatory' })}</span>
            ) : (
              ''
            )}
          </div>
          <div className={textInputStyles.inputWrapper + ' ' + textInputStyles.noIcon}>
            <TextInput
              id="contactpersonphoneId"
              name="contactPersonPhone"
              type="text"
              maxLength="13"
              active={this.props.reduxContactPerson.phone.length > 0 ? 1 : 0}
              label={this.props.intl.formatMessage({ id: 'venuePage.reservationInfo.reservationForm.telephone' }) + ` *`}
              value={this.props.reduxContactPerson.phone}
              onChange={this.handleChangeContactPersonPhone}
              onBlur={this.validateContactPersonPhone}
              required={true}
            />
            {this.props.reduxContactPerson.phone.length >= 1 && this.state.contactPersonPhone.showError && (
              <span className={textInputStyles.reminder}>{this.props.intl.formatMessage({ id: 'venuePage.reservationInfo.reservationForm.phoneNumberHint' })}</span>
            )}
            {this.props.reduxContactPerson.phone.length < 1 && this.state.contactPersonPhone.showError && (
              <span className={textInputStyles.reminder}>{this.props.intl.formatMessage({ id: 'venuePage.reservationInfo.reservationForm.phoneNumberIsMandatory' })}</span>
            )}
          </div>
          <div className={textInputStyles.inputWrapper + ' ' + textInputStyles.noIcon}>
            <TextInput
              id="contactpersonemailId"
              name="contactPersonEmail"
              type="email"
              active={this.props.reduxContactPerson.email.length > 0 ? 1 : 0}
              label={this.props.intl.formatMessage({ id: 'venuePage.reservationInfo.reservationForm.email' }) + ` *`}
              value={this.props.reduxContactPerson.email}
              onChange={this.handleChangeContactPersonEmail}
              onBlur={this.validateEmail}
              required={true}
            />
            {this.props.reduxContactPerson.email.length >= 1 && this.state.contactPersonEmail.showError ? (
              <span className={textInputStyles.reminder}>{this.props.intl.formatMessage({ id: 'venuePage.reservationInfo.reservationForm.emailHint' })}</span>
            ) : (
              ''
            )}
            {this.props.reduxContactPerson.email.length < 1 && this.state.contactPersonEmail.showError ? (
              <span className={textInputStyles.reminder}>{this.props.intl.formatMessage({ id: 'venuePage.reservationInfo.reservationForm.emailIsMandatory' })}</span>
            ) : (
              ''
            )}
          </div>
        </div>
      </>
    ) : (
      ''
    )
  }

  render() {
    const contactperson = this.renderContactPersonInformation()

    return (
      <div>
        <div className={venueListItemStyles.contentContainer}>
          <p className={fonts.legend}>
            <FormattedMessage id="venuePage.reservationInfo.reservationForm.reservationCreatorInfo" />
          </p>
          <div className={userInformationStyles.fieldsetFirst}>
            <div className={textInputStyles.inputWrapper + ' ' + textInputStyles.noIcon}>
              <TextInput
                id="firstnameId"
                name="userFirstname"
                type="text"
                active={this.props.reduxUser.firstname.length > 0 ? 1 : 0}
                label={this.props.intl.formatMessage({ id: 'venuePage.reservationInfo.reservationForm.firstName' }) + ` *`}
                value={this.props.reduxUser.firstname}
                onChange={this.handleChangeUserFirstname}
                onBlur={this.validateName}
                required={true}
              />
              {this.state.userFirstname.showError ? (
                <span className={textInputStyles.reminder}>{this.props.intl.formatMessage({ id: 'venuePage.reservationInfo.reservationForm.firstNameIsMandatory' })}</span>
              ) : (
                ''
              )}
            </div>
            <div className={textInputStyles.inputWrapper + ' ' + textInputStyles.noIcon}>
              <TextInput
                id="lastnameId"
                name="userLastname"
                type="text"
                active={this.props.reduxUser.lastname.length > 0 ? 1 : 0}
                label={this.props.intl.formatMessage({ id: 'venuePage.reservationInfo.reservationForm.lastName' }) + ` *`}
                value={this.props.reduxUser.lastname}
                onChange={this.handleChangeUserLastname}
                onBlur={this.validateName}
                required={true}
              />
              {this.state.userLastname.showError ? (
                <span className={textInputStyles.reminder}>{this.props.intl.formatMessage({ id: 'venuePage.reservationInfo.reservationForm.lastNameIsMandatory' })}</span>
              ) : (
                ''
              )}
            </div>
            <div className={textInputStyles.inputWrapper + ' ' + textInputStyles.noIcon}>
              <TextInput
                id="phoneId"
                name="userPhone"
                type="text"
                maxLength="13"
                active={this.props.reduxUser.phone.length > 0 ? 1 : 0}
                label={this.props.intl.formatMessage({ id: 'venuePage.reservationInfo.reservationForm.telephone' }) + ` *`}
                value={this.props.reduxUser.phone}
                onChange={this.handleChangeUserPhone}
                onBlur={this.validatePhone}
                required={true}
              />
              {this.props.reduxUser.phone.length >= 1 && this.state.userPhone.showError && (
                <span className={textInputStyles.reminder}>{this.props.intl.formatMessage({ id: 'venuePage.reservationInfo.reservationForm.phoneNumberHint' })}</span>
              )}
              {this.props.reduxUser.phone.length < 1 && this.state.userPhone.showError && (
                <span className={textInputStyles.reminder}>{this.props.intl.formatMessage({ id: 'venuePage.reservationInfo.reservationForm.phoneNumberIsMandatory' })}</span>
              )}
            </div>
            <div className={textInputStyles.inputWrapper + ' ' + textInputStyles.noIcon}>
              <TextInput
                id="emailId"
                name="userEmail"
                type="email"
                active={this.props.reduxUser.email.length > 0 ? 1 : 0}
                label={this.props.intl.formatMessage({ id: 'venuePage.reservationInfo.reservationForm.email' }) + ` *`}
                value={this.props.reduxUser.email}
                onChange={this.handlechangeUserEmail}
                onBlur={this.validateEmail}
                required={true}
              />
              {this.props.reduxUser.email.length >= 1 && this.state.userEmail.showError ? (
                <span className={textInputStyles.reminder}>{this.props.intl.formatMessage({ id: 'venuePage.reservationInfo.reservationForm.emailHint' })}</span>
              ) : (
                ''
              )}
              {this.props.reduxUser.email.length < 1 && this.state.userEmail.showError ? (
                <span className={textInputStyles.reminder}>{this.props.intl.formatMessage({ id: 'venuePage.reservationInfo.reservationForm.emailIsMandatory' })}</span>
              ) : (
                ''
              )}
            </div>
          </div>
          <div className={userInformationStyles.fieldsetSecond}>
            <div className={textInputStyles.inputWrapper + ' ' + textInputStyles.noIcon}>
              <TextInput
                id="companyNameId"
                name="userCompanyName"
                type="text"
                active={this.props.reduxUser.companyName.length > 0 ? 1 : 0}
                label={this.props.intl.formatMessage({ id: 'venuePage.reservationInfo.reservationForm.company' })}
                value={this.props.reduxUser.companyName}
                onChange={this.handleChangeUserCompanyName}
                onBlur={this.validateCompanyName}
              />
              {this.state.userCompanyName.showError ? (
                <span className={textInputStyles.reminder}>{this.props.intl.formatMessage({ id: 'venuePage.reservationInfo.reservationForm.companyNameHint' })}</span>
              ) : (
                ''
              )}
            </div>
            <div className={textInputStyles.inputWrapper + ' ' + textInputStyles.noIcon}>
              <TextInput
                id="companyId"
                name="userCompanyId"
                type="text"
                active={this.props.reduxUser.VATnumber.length > 0 ? 1 : 0}
                label={this.props.intl.formatMessage({ id: 'venuePage.reservationInfo.reservationForm.businessID' })}
                value={this.props.reduxUser.VATnumber}
                onChange={this.handleChangeUserVAT}
                onBlur={this.validateCompanyId}
              />
              {this.state.userCompanyId.showError ? (
                <span className={textInputStyles.reminder}>{this.props.intl.formatMessage({ id: 'venuePage.reservationInfo.reservationForm.businessIdHint' })}</span>
              ) : (
                ''
              )}
            </div>
          </div>
          <div className={userInformationStyles.fieldsetThird}>
            <div className={textInputStyles.inputWrapper + ' ' + textInputStyles.noIcon}>
              <TextInput
                id="streetAdressId"
                name="userStreetAddress"
                type="text"
                active={this.props.reduxUser.streetAddress.length > 0 ? 1 : 0}
                label={this.props.intl.formatMessage({ id: 'venuePage.reservationInfo.reservationForm.streetAddress' }) + ` *`}
                value={this.props.reduxUser.streetAddress}
                onChange={this.handleChangeUserStreetAddress}
                onBlur={this.validateStreetAddress}
                required={true}
              />
              {this.state.userStreetAddress.showError ? (
                <span className={textInputStyles.reminder}>{this.props.intl.formatMessage({ id: 'venuePage.reservationInfo.reservationForm.streetAddressMandatory' })}</span>
              ) : (
                ''
              )}
            </div>
            <div style={{ width: '180px' }} className={textInputStyles.inputWrapper + ' ' + textInputStyles.noIcon}>
              <TextInput
                id="streetCodeId"
                name="userZIP"
                type="number"
                minLength="5"
                maxLength="5"
                active={this.props.reduxUser.zipCode.length > 0 ? 1 : 0}
                label={this.props.intl.formatMessage({ id: 'venuePage.reservationInfo.reservationForm.postalCode' }) + ` *`}
                value={this.props.reduxUser.zipCode}
                onChange={this.handleChangeUserStreetCode}
                onBlur={this.validateZIP}
                required={true}
              />
              {this.state.userZIP.showError ? (
                <span className={textInputStyles.reminder}>{this.props.intl.formatMessage({ id: 'venuePage.reservationInfo.reservationForm.zipAddressMandatory' })}</span>
              ) : (
                ''
              )}
            </div>
            <div className={textInputStyles.inputWrapper + ' ' + textInputStyles.noIcon}>
              <TextInput
                id="cityId"
                name="userCity"
                type="text"
                active={this.props.reduxUser.city.length > 0 ? 1 : 0}
                label={this.props.intl.formatMessage({ id: 'venuePage.reservationInfo.reservationForm.city' }) + ` *`}
                value={this.props.reduxUser.city}
                onChange={this.handleChangeUserCity}
                onBlur={this.validateCity}
                required={true}
              />
              {this.state.userCity.showError ? (
                <span className={textInputStyles.reminder}>{this.props.intl.formatMessage({ id: 'venuePage.reservationInfo.reservationForm.cityMandatory' })}</span>
              ) : (
                ''
              )}
            </div>
            <CountryDropdown handleOnChange={this.handleChangeUserCountry} country={this.props.reduxUser.country} />
            <p className={fonts.smallParagraphNoMargin}>
              <FormattedMessage id="venuePage.reservationInfo.customerRegisterInfo" />{' '}
              <a href={this.renderCustomerRegisterUrl()} className={fonts.textLink} target="_blank" rel="noopener noreferrer">
                <FormattedMessage id="venuePage.reservationInfo.customerRegisterLinkText" />
              </a>
              {'.'}
            </p>
          </div>
          <div className={userInformationStyles.checkboxContainer}>
            <CheckBox
              name="hasContactPerson"
              value="hascontactperson"
              label={this.props.intl.formatMessage({ id: 'venuePage.reservationInfo.reservationForm.contactPerson' })}
              onChange={this.handleChangeHasContactPerson}
              checked={this.props.hasContactPerson}
            />
          </div>

          {contactperson}

          <div className={userInformationStyles.fieldsetFifth}>
            <p className={fonts.legend}>{this.props.intl.formatMessage({ id: 'venuePage.reservationInfo.reservationForm.paymentMethod' })}</p>
            <RadioButton
              value="PaymentOnSite"
              label={this.props.intl.formatMessage({ id: 'venuePage.reservationInfo.reservationForm.paymentUponArrival' })}
              checked={this.props.reduxPaymentType === 'PaymentOnSite'}
              onChange={this.handleChangePaymentType}
            />
            <RadioButton
              value="Invoice"
              label={this.props.intl.formatMessage({ id: 'venuePage.reservationInfo.reservationForm.invoice' })}
              checked={this.props.reduxPaymentType === 'Invoice'}
              onChange={this.handleChangePaymentType}
            />
            {this.prePaymentDisabled(this.props.reduxReservationDate) ? (
              <RadioButton
                value="Prepayed"
                label={this.props.intl.formatMessage({ id: 'venuePage.reservationInfo.reservationForm.paymentAdvanceNotAvailable' })}
                checked={this.props.reduxPaymentType === 'Prepayed'}
                onChange={this.handleChangePaymentType}
                disabled
              />
            ) : (
              <RadioButton
                value="Prepayed"
                label={this.props.intl.formatMessage({ id: 'venuePage.reservationInfo.reservationForm.paymentAdvance' })}
                checked={this.props.reduxPaymentType === 'Prepayed'}
                onChange={this.handleChangePaymentType}
              />
            )}
          </div>

          <div className={userInformationStyles.fieldsetSixth}>
            <p className={fonts.legend}>{this.props.intl.formatMessage({ id: 'venuePage.reservationInfo.reservationForm.bonusBenefit' })}</p>
            <p className={fonts.smallParagraphNoMargin}>{this.props.intl.formatMessage({ id: 'venuePage.reservationInfo.reservationForm.bonusBenefitInfo' })}</p>
            <div className={userInformationStyles.cardIconContainer}>
              <SEtukorttiLogo className={userInformationStyles.cardIcon} alt="" />
              <SCardLogo className={userInformationStyles.cardIcon} alt="" />
            </div>
          </div>

          <PreReservationbutton
            edge={this.props.edge}
            afterPreReservationClick={index => this.props.showNextSection(index)}
            changeUserMessage={message => this.props.changeUserMessage(message)}
            disabled={this.nextButtonDisabled()}
            buttonText={this.props.intl.formatMessage({ id: 'venuePage.nextPhase' })}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    reduxUser: state.user,
    reduxContactPerson: state.contactPerson,
    reduxPaymentType: state.paymentType,
    reduxReservationDate: state.search.reservationDate,
  }
}

const mapDispatchToProps = dispatch => ({
  dispatchFirstname: callbackLink => dispatch(changeUserFirstname(callbackLink)),
  dispatchLastname: callbackLink => dispatch(changeUserLastname(callbackLink)),
  dispatchPhone: callbackLink => dispatch(changeUserPhone(callbackLink)),
  dispatchEmail: callbackLink => dispatch(changeUserEmail(callbackLink)),
  dispatchCompanyName: callbackLink => dispatch(changeUserCompanyName(callbackLink)),
  dispatchVAT: callbackLink => dispatch(changeUserVAT(callbackLink)),
  dispatchStreetAddress: callbackLink => dispatch(changeUserStreetAddress(callbackLink)),
  dispatchStreetCode: callbackLink => dispatch(changeUserStreetCode(callbackLink)),
  dispatchCity: callbackLink => dispatch(changeUserCity(callbackLink)),
  dispatchUserCountry: callbackLink => dispatch(changeUserCountry(callbackLink)),
  dispatchHasContactPerson: callbackLink => dispatch(changeHasContactPerson(callbackLink)),
  dispatchContactPersonFirstname: callbackLink => dispatch(changeContactPersonFirstname(callbackLink)),
  dispatchContactPersonLastname: callbackLink => dispatch(changeContactPersonLastname(callbackLink)),
  dispatchContactPersonPhone: callbackLink => dispatch(changeContactPersonPhone(callbackLink)),
  dispatchContactPersonEmail: callbackLink => dispatch(changeContactPersonEmail(callbackLink)),
  dispatchPaymentType: callbackLink => dispatch(changePaymentType(callbackLink)),
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(VenueReservationUserInformation))
