import React from 'react'
import fonts from '../../../../../../../styles/fonts.module.css'
// import additionalListItemStyles from "./additionalListItem.module.css"
import DefaultButton from '../../../../../../../components/common/defaultButton/defaultButton.js'

const AdditionalListItem = props => {
  return (
    <div>
      <h3 className={fonts.legend}>{props.header}</h3>
      <p className={fonts.smallParagraph}>{props.desc}</p>
      <DefaultButton type="smallDefault" onClick={props.togglePopup} buttonText={props.buttonText} />
    </div>
  )
}

export default AdditionalListItem
